import React, {useState} from 'react';
import {Link, NavLink, useLocation} from "react-router-dom";
import s from './Footer.module.css'
import logo from '../../assets/img/logo.png'
import logoTr from '../../assets/img/logo_tr_white.png'
import login from "../../assets/img/Log_in.png";
import facebook from "../../assets/img/Facebook.png";
import mail from "../../assets/img/Mail.png";
import whatsapp from "../../assets/img/WhatsApp.png";
import linkedin from "../../assets/img/LinkedIN.png";
import telegram from "../../assets/img/Telegram.png";
import signal from "../../assets/img/Signal.png";
import Insta from "../../assets/img/Insta.png";
import {Link as ScrollLink} from "react-scroll";
import MyPopup from "../../utils/MyPopup/MyPopup";
import {t} from "i18next";
import {withTranslation} from "react-i18next";

const Footer = () => {

    const [popupIsVisible, setPopupIsVisible] = useState(false)

    const location = useLocation()

    const isLogin = () => {
        return location.pathname === '/'
    }

    const isSignup = () => {
        return location.pathname === '/signup'
    }

    const getLogo = () => {
        if (localStorage.getItem('lang') === 'tr') {
            return <img src={logoTr} alt="logo" className={s.logoBlock}/>
        } else {
            return <img src={logo} alt="logo" className={s.logoBlock}/>
        }
    }

    const getTermsAndConditionsLink = () => {
        if (localStorage.getItem('lang') === 'en') {
            return 'TURKISH_INTERNATIONAL_INVESTMENT_BANK_LIMITED_TERMS_AND_CONDITIONS.pdf'
        } else if (localStorage.getItem('lang') === 'tr') {
            return 'TURKIYE_UYB_SARTLAR_VE_KOSULLAR.pdf'
        } else {
            return 'Условия_и_Положения.pdf'
        }
    }

    const isScrollOrIsLink = () => {
        return (
            location.pathname === "/contact-us" ||
            location.pathname === "/api-guide" ||
            location.pathname === "/investor-relations"
        );
    };

    if (isScrollOrIsLink()) {
        return <div className={s.footerWrapper}>
            <div className={s.left}></div>
            <div className={s.right}>
                <MyPopup visible={popupIsVisible} setVisible={setPopupIsVisible}/>
                <div className={s.rightBackground}>
                    <div className={s.rightContent}>
                        <div>
                            <div>
                                {getLogo()}
                            </div>
                            <div className={s.links_wrapper}>
                                <div className={s.linksBlock}>
                                    <Link to="/" className={s.link}>
                                        <div>{t('main.banking')}</div>
                                    </Link>

                                    <Link to="/" className={s.link}>
                                        <div>{t('main.savings_account')}</div>
                                    </Link>

                                    <Link to="/" className={s.link}>
                                        <div>{t('main.debit_cards')}</div>
                                    </Link>

                                    <Link to="/" className={s.link}>
                                        <div>{t('main.e_money')}</div>
                                    </Link>
                                </div>
                                <div className={s.linksBlock}>
                                    <Link to="/api-guide" className={s.link} activeClassName={s.active}>
                                        {t('main.api_guide')}
                                    </Link>
                                    <Link to="/contact-us" className={s.link} activeClassName={s.active}>
                                        {t('main.contact_us')}
                                    </Link>
                                    {/*<Link to="/investor-relations" className={s.link} activeClassName={s.active}>*/}
                                    {/*    {t('main.investor_relations')}*/}
                                    {/*</Link>*/}
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className={s.socialBlock}>
                                <div className={s.loginButtonBlock}>
                                    <NavLink to='/login'>
                                        <div className={s.loginButtonBlock}>
                                            <img src={login} alt="login"/>
                                            <span className={s.login}>{t('main.login')}</span>
                                        </div>
                                    </NavLink>
                                    <div>
                                        <button className={s.signUpButton} onClick={() => {setPopupIsVisible(true)}}>{t('main.sign_up')}</button>
                                    </div>
                                </div>
                                <div className={s.socialContainer}>
                                    <div className={s.socialRow}>
                                        <a href="https://www.linkedin.com/company/turkish-international-investment-bank" target="_blank" className={s.socialLink}><img src={linkedin} alt=""/></a>
                                        <a href="https://www.facebook.com/turkish.international.bank" target="_blank" className={s.socialLink}><img src={facebook} alt=""/></a>
                                        <a href="https://instagram.com/turkishintinvbank?igshid=YmMyMTA2M2Y=" className={s.socialLink}><img src={Insta} alt=""/></a>
                                        <a href="https://wa.me/+447700306857" target="_blank" className={s.socialLink}><img src={whatsapp} alt=""/></a>
                                    </div>
                                    <div>
                                        <a href="https://t.me/+447700306857" target="_blank" className={s.socialLink}><img src={telegram} alt=""/></a>
                                        <a href="https://signal.group/#CjQKIClfotfLAbvrDwEvKW9gwt-LeX5LN92W6A1JYttrZ9kVEhCPZ5xtOyGZu5NXGGKzC95q" target="_blank" className={s.socialLink}><img src={signal} alt=""/></a>
                                        <a href="mailto:info@dbfinancehouse.com" className={s.socialLink}><img src={mail} alt=""/></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={s.allRightsBlock}>
                        <div>
                            <div>
                                <span>{t('main.privacy')}</span>
                                <a className={s.line} href={getTermsAndConditionsLink()} target="_blank"><span>{t('main.terms')}</span></a>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    }

    return (
        <div>
            {isLogin() || isSignup() ? <div></div> : <div className={s.footerWrapper}>
                <div className={s.left}></div>
                <div className={s.right}>
                    <MyPopup visible={popupIsVisible} setVisible={setPopupIsVisible}/>
                    <div className={s.rightBackground}>
                        <div className={s.rightContent}>
                            <div>
                                <div>
                                    {getLogo()}
                                </div>
                                <div className={s.links_wrapper}>
                                    <div className={s.linksBlock}>
                                        <ScrollLink
                                            activeClass={s.active}
                                            to="2"
                                            spy={true}
                                            smooth={true}
                                            offset={0}
                                            duration={500}
                                        >
                                            <div className={s.link}>{t('main.banking')}</div>
                                        </ScrollLink>

                                        <ScrollLink
                                            activeClass={s.active}
                                            to="3"
                                            spy={true}
                                            smooth={true}
                                            offset={0}
                                            duration={500}
                                        >
                                            <div className={s.link}>{t('main.savings_account')}</div>
                                        </ScrollLink>

                                        <ScrollLink
                                            activeClass={s.active}
                                            to="4"
                                            spy={true}
                                            smooth={true}
                                            offset={0}
                                            duration={500}
                                        >
                                            <div className={s.link}>{t('main.debit_cards')}</div>
                                        </ScrollLink>

                                        <ScrollLink
                                            activeClass={s.active}
                                            to="5"
                                            spy={true}
                                            smooth={true}
                                            offset={0}
                                            duration={500}
                                        >
                                            <div className={s.link}>{t('main.e_money')}</div>
                                        </ScrollLink>
                                    </div>
                                    <div className={s.linksBlock}>
                                        <Link to="/api-guide" className={s.link} activeClassName={s.active}>
                                            {t('main.api_guide')}
                                        </Link>
                                        <Link to="/contact-us" className={s.link} activeClassName={s.active}>
                                            {t('main.contact_us')}
                                        </Link>
                                        {/*<Link to="/investor-relations" className={s.link} activeClassName={s.active}>*/}
                                        {/*    {t('main.investor_relations')}*/}
                                        {/*</Link>*/}
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className={s.socialBlock}>
                                    <div className={s.loginButtonBlock}>
                                        <NavLink to='/login'>
                                            <div className={s.loginButtonBlock}>
                                                <img src={login} alt="login"/>
                                                <span className={s.login}>{t('main.login')}</span>
                                            </div>
                                        </NavLink>
                                        <div>
                                            <button className={s.signUpButton} onClick={() => {setPopupIsVisible(true)}}>{t('main.sign_up')}</button>
                                        </div>
                                    </div>
                                    <div className={s.socialContainer}>
                                        <div className={s.socialRow}>
                                            <a href="https://www.linkedin.com/company/turkish-international-investment-bank" target="_blank" className={s.socialLink}><img src={linkedin} alt=""/></a>
                                            <a href="https://www.facebook.com/turkish.international.bank" target="_blank" className={s.socialLink}><img src={facebook} alt=""/></a>
                                            <a href="https://instagram.com/turkishintinvbank?igshid=YmMyMTA2M2Y=" target="_blank" className={s.socialLink}><img src={Insta} alt=""/></a>
                                            <a href="https://wa.me/+447700306857" target="_blank" className={s.socialLink}><img src={whatsapp} alt=""/></a>
                                        </div>
                                        <div>
                                            <a href="https://t.me/+447700306857" target="_blank" className={s.socialLink}><img src={telegram} alt=""/></a>
                                            <a href="https://signal.group/#CjQKIClfotfLAbvrDwEvKW9gwt-LeX5LN92W6A1JYttrZ9kVEhCPZ5xtOyGZu5NXGGKzC95q" target="_blank" className={s.socialLink}><img src={signal} alt=""/></a>
                                            <a href="mailto:info@dbfinancehouse.com" className={s.socialLink}><img src={mail} alt=""/></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={s.allRightsBlock}>
                            <div>
                                <div>
                                    <span>{t('main.privacy')}</span>
                                        <a className={s.line} href={getTermsAndConditionsLink()} target="_blank"><span>{t('main.terms')}</span></a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>}

            <div className={s.mobile_footer}>
                <div className={s.social_links_block}>
                    <a href="https://www.facebook.com/turkish.international.bank" target="_blank" className={s.facebook}></a>
                    <a href="https://www.linkedin.com/company/turkish-international-investment-bank" target="_blank" className={s.linkedin}></a>
                    <a href="https://wa.me/+447700306857" target="_blank" className={s.whatsapp}></a>
                    <a href="mailto:info@dbfinancehouse.com" target="_blank" className={s.mail}></a>
                    <a href="https://t.me/+447700306857" target="_blank" className={s.telegram}></a>
                    <a href="https://signal.group/#CjQKIClfotfLAbvrDwEvKW9gwt-LeX5LN92W6A1JYttrZ9kVEhCPZ5xtOyGZu5NXGGKzC95q" target="_blank" className={s.signal}></a>
                    <a href="https://instagram.com/turkishintinvbank?igshid=YmMyMTA2M2Y=" className={s.insta}></a>
                </div>

                <div className={s.allRightsBlock}>
                    <span>{t('main.privacy')}</span>
                        <a className={s.line} href={getTermsAndConditionsLink()} target="_blank"><span>{t('main.terms')}</span></a>
                </div>

            </div>

        </div>
    );
};


export default withTranslation()(Footer)
