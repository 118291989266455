import React, {useState} from 'react';
import { slide as Menu } from 'react-burger-menu';
import './Sidebar.css';
import {Link as ScrollLink} from "react-scroll";
import {t} from "i18next";
import {Link, useLocation} from "react-router-dom";
import s from "../header/Header.module.css";
import login from "../../assets/img/Log_in.png";
import CloseImg from "../../assets/img/Close.png";
import MyPopup from "../../utils/MyPopup/MyPopup";
import i18n from "../../i18n";

export default props => {

    const [popupIsVisible, setPopupIsVisible] = useState(false)
    const location = useLocation();

    const isScroll = () => {
        return (
            location.pathname === "/contact-us" ||
            location.pathname === "/api-guide" ||
            location.pathname === "/investor-relations"
        );
    };

    const handleChangeLanguage = (e) => {
        const lang = e.target.textContent.toLowerCase()
        i18n.changeLanguage(lang);
        localStorage.setItem('lang', lang)
    }

    if (isScroll()) {
        return (
            <Menu isOpen={props.isMenuOpen}>
                <MyPopup visible={popupIsVisible} setVisible={setPopupIsVisible}/>

                <div className="close_btn" onClick={() => {props.setIsMenuOpen(false)}}>
                    <img src={CloseImg} alt=""/>
                </div>

                <div className="lng_block_mob">
                    <div className={localStorage.getItem('lang') === 'en' ? s.active_lang : s.lng}
                         onClick={handleChangeLanguage}>
                        EN
                    </div>
                    <div className={localStorage.getItem('lang') === 'ru' ? s.active_lang : s.lng}
                         onClick={handleChangeLanguage}>
                        RU
                    </div>
                    <div className={localStorage.getItem('lang') === 'tr' ? s.active_lang : s.lng}
                         onClick={handleChangeLanguage}>
                        TR
                    </div>
                </div>

                {/*<Link to="/contact-us" activeClassName={s.active} className='link' onClick={() => {props.setIsMenuOpen(false)}}>*/}
                {/*    {t('main.contact_us')}*/}
                {/*</Link>*/}

                {/*<Link to="/api-guide" activeClassName={s.active}>*/}
                {/*    Api guide*/}
                {/*</Link>*/}

                {/*<Link to="/investor-relations" activeClassName={s.active} className='link' onClick={() => {props.setIsMenuOpen(false)}}>*/}
                {/*    {t('main.investor_relations')}*/}
                {/*</Link>*/}

                <Link to="/" activeClassName={s.active} className='link' onClick={() => {props.setIsMenuOpen(false)}}>
                    {t('login.back')}
                </Link>

                <Link to="/login">
                    <div className="loginButtonBlock" onClick={() => {props.setIsMenuOpen(false)}}>
                        <img src={login} alt="login"/>
                        <span className="login">{t('main.login')}</span>
                    </div>
                </Link>

                <div>
                    <button className="signUpButton"
                            onClick={() => {
                                setPopupIsVisible(true)
                            }}
                    >{t('main.sign_up')}
                    </button>
                </div>
            </Menu>
        )
    }

    return (
        <Menu isOpen={props.isMenuOpen}>
            <MyPopup visible={popupIsVisible} setVisible={setPopupIsVisible}/>

            <div className="close_btn" onClick={() => {props.setIsMenuOpen(false)}}>
                <img src={CloseImg} alt=""/>
            </div>

            <div className="lng_block_mob">
                <div className={localStorage.getItem('lang') === 'en' ? s.active_lang : s.lng}
                     onClick={handleChangeLanguage}>
                    EN
                </div>
                <div className={localStorage.getItem('lang') === 'ru' ? s.active_lang : s.lng}
                     onClick={handleChangeLanguage}>
                    RU
                </div>
                <div className={localStorage.getItem('lang') === 'tr' ? s.active_lang : s.lng}
                     onClick={handleChangeLanguage}>
                    TR
                </div>
            </div>

            <ScrollLink
                to="2"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
            >
                <div className='link' onClick={() => {props.setIsMenuOpen(false)}}>
                    {t('main.banking')}
                </div>
            </ScrollLink>

            <ScrollLink
                to="3"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
            >
                <div className='link' onClick={() => {props.setIsMenuOpen(false)}}>{t('main.savings_account')}</div>
            </ScrollLink>

            <ScrollLink
                to="4"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
            >
                <div className='link' onClick={() => {props.setIsMenuOpen(false)}}>{t('main.debit_cards')}</div>
            </ScrollLink>

            <ScrollLink
                to="5"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
            >
                <div className='link' onClick={() => {props.setIsMenuOpen(false)}}>{t('main.e_money')}</div>
            </ScrollLink>

            <Link to="/api-guide" activeClassName={s.active} className='link' onClick={() => {props.setIsMenuOpen(false)}}>
                {t('main.api_guide')}
            </Link>

            <Link to="/contact-us" activeClassName={s.active} className='link' onClick={() => {props.setIsMenuOpen(false)}}>
                {t('main.contact_us')}
            </Link>

            {/*<Link to="/api-guide" activeClassName={s.active}>*/}
            {/*    Api guide*/}
            {/*</Link>*/}

            {/*<Link to="/investor-relations" activeClassName={s.active} className='link' onClick={() => {props.setIsMenuOpen(false)}}>*/}
            {/*    {t('main.investor_relations')}*/}
            {/*</Link>*/}

            <Link to="/login">
                <div className="loginButtonBlock" onClick={() => {props.setIsMenuOpen(false)}}>
                    <img src={login} alt="login"/>
                    <span className="login">{t('main.login')}</span>
                </div>
            </Link>

            <div>
                <button className="signUpButton"
                        onClick={() => {
                            setPopupIsVisible(true)
                        }}
                >{t('main.sign_up')}
                </button>
            </div>
        </Menu>
    );
};
