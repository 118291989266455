import React from "react";
import i18n from "i18next";
import {initReactI18next} from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
    en: {
        translation: {
            main: {
                'turkey': 'TURKISH',
                'international': 'INTERNATIONAL',
                'investment': 'INVESTMENT',
                'bank': 'BANK',
                'login': 'LOGIN',
                'sign_up': 'Account opening',
                'contact_us': 'Contact Us',
                'investor_relations': 'Investor relations',
                'banking': 'Banking',
                'savings_account': 'Savings Account',
                'debit_cards': 'Debit Cards',
                'e_money': 'E-Money',
                'about_us': 'About Us',
                'api_guide': 'API Guide',
                'desc_title': 'Full set of general and private banking services',
                'desc_subtitle': 'Experienced, professional management and stellar Board of\n' +
                    '                            Directors. Strict but reasonable compliance policies.',
                'open_account': 'Account opening',
                'banking_service': 'Banking Service',
                'banking_service_content': 'Full set of general and private banking services. Experienced,\n' +
                    '                                professional management and stellar Board of Directors. Strict\n' +
                    '                                but reasonable compliance policies. Multi-currency accounts for\n' +
                    '                                global payments and high-yield deposits. Universally accepted\n' +
                    '                                debit cards. Fast global money transfers. Superb account\n' +
                    '                                security and data protection. A full suite of e-banking tools\n' +
                    '                                online and in-app. Seamless link to crypto asset. Friendly,\n' +
                    '                                competent support staff. That\'s what we do and who we are!',
                'universal_personal_accounts': 'Universal personal accounts',
                'universal_personal_accounts_desc': 'TURKISH INTERNATIONAL INVESTMENT BANK offers personal accounts, which can be applied for\n' +
                    '                                and opened online. The package of features of your personal account will be crafted to\n' +
                    '                                perfectly fit your needs. Our general banking accounts offer everything you require to\n' +
                    '                                simplify and streamline the daily financial life of your whole family. TURKISH\n' +
                    '                                INTERNATIONAL INVESTMENT BANK clients enjoy the level of personalized management of their\n' +
                    '                                banking and investment portfolios unprecedented among our industry peers across the\n' +
                    '                                globe.',
                'fueling_your_e_commerce': 'Fueling your e-commerce',
                'fueling_your_e_commerce_desc': 'TURKISH INTERNATIONAL INVESTMENT BANK provides everything you need to launch and manage\n' +
                    '                                your international e-commerce. Our ability to manage multiple currencies, support\n' +
                    '                                locations in key parts of the world, our network of reliable correspondent banks and the\n' +
                    '                                expert IT team - is exactly what you need to launch and operate the most challenging\n' +
                    '                                business e-commerce missions in a stable and reasonably-priced environment you\'ve been\n' +
                    '                                looking for.',
                'acquiring_services': 'Acquiring services',
                'acquiring_services_desc_p1': 'The Turkish International Investment Bank will perform technical support of one of the most important services of the site - acquiring. Acceptance of payments on the site should work like clockwork, because it depends on how quickly and accurately payments will be processed. You will be able to offer your new clients several methods of withdrawal.',
                'acquiring_services_desc_p2': 'Cooperating with us means:',
                'acquiring_services_desc_p3': '-Guaranteed security of assets and personal data of clients.',
                'acquiring_services_desc_p4': '-Qualified technical support 24/7.',
                'acquiring_services_desc_p5': '-Possibility to accept and process payments in cryptocurrency.',
                'acquiring_services_desc_p6': '-Intuitive interface.',
                'saving': 'Savings',
                'account': 'Account',
                'deposit_with': 'Deposit with Turkish International',
                'investment_bank': 'Investment Bank',
                'saving_desc': 'TURKISH INTERNATIONAL INVESTMENT BANK pays up to 7% on Certificates of Deposits in Savings\n' +
                    '                            Accounts in USD and EUR. No strings attached, just straight\n' +
                    '                            and simple quarterly payments of interest. You must be an existing\n' +
                    '                            TURKISH INTERNATIONAL INVESTMENT BANK account-holder to qualify.',
                'debit_cards_block_title': 'Debit Cards',
                'debit_cards_block_subtitle': 'Visa® and UnionPay debit cards for you and your partners',
                'debit_cards_block_desc': 'TIIB virtual or bank card will provide you with access to your money at any ' +
                    'time and everywhere. Our 3D security system for your online payments will protect your finances ' +
                    'from fraudsters. You can use an ATM or pay for your purchase in any store worldwide. Your finances ' +
                    'have no borders with Turkish International Investment Bank payment cards.',
                'debit_cards_block_desc2': 'Open an account today at TIIB and feel the freedom of your money!',
                'e_money_title_1': 'For business, you and yours',
                'e_money_desc_1': 'TURKISH INTERNATIONAL INVESTMENT BANK is a global financial institution with a personal touch. If you\'re running a huge business or just a small errand - we may have a financial solution for you!\n' +
                    'We are both international and multi-currency and as local as the ATM around the corner. Combining brick and mortar stability with the high-tech security of our cloud-based\n' +
                    'e-wallets, we are everything you want your global bank to be!',
                'e_money_title_2': 'SEPA, SWIFT',
                'e_money_desc_2': 'We offer accounts in various currencies with access to the\n' +
                    '                                European SEPA and international SWIFT systems. Private bankers\n' +
                    '                                are available to the high net worth clients while everyone\'s\n' +
                    '                                daily needs are addressed by our versatile personal e-banking\n' +
                    '                                solutions, including virtual and prepaid debit cards, fast\n' +
                    '                                peer-to-peer transfers and multiple other cool features managed\n' +
                    '                                online.',
                'privacy': '© 2022 Turkish International Investment Bank Limited. All rights reserved.',
                'terms': 'Terms and Conditions',
                'send': 'send',
                'valid_email': 'Enter a valid email',
                'all_fields': 'All fields must be filled'
            },
            calc: {
                'title': 'Calculate amount of interest',
                'deposit_amount': 'deposit amount',
                'period': 'period',
                'month': 'month',
                'currency': 'currency',
                'open_account': 'Account opening',
                'interest': 'Interest',
                'in': 'in',
                'month_2': 'month',
                'calc_sentence_1': 'you may earn',
                'calc_sentence_2': 'of interest on your deposit',
            },
            contact: {
                'contact': 'Contact ',
                'us': 'Us',
                'reach_us': 'You can reach us',
                'welcome_to': 'Welcome to Turkish International Investment Bank',
                'name': 'Name',
                'phone': 'Phone',
                'message': 'Message',
                'cyprus_title': 'Representative office in Cyprus:',
                'cyprus_address_1': '57 Spyrou Kyprianou Avenue,',
                'cyprus_address_2': 'Bybloserve Business Center,',
                'cyprus_address_3': 'Larnaca 6051, Cyprus',
            },
            popup: {
                'title': 'Send a request',
                'subtitle': 'Soon our specialists will contact you to create',
                'name': 'Name',
                'phone': 'Phone',
                'email': 'E-mail',
                'send': 'send',
                'success': 'Success!',
                'ok': 'OK',
                'title_opening': 'Online Account Opening request',
                'download': 'Download the Account Opening Form',
                'personal': 'Account Opening Form Personal',
                'corporate': 'Account Opening Form Corporate',
                'upload': 'Upload the Completed and Signed Form',
                'add': 'ADD',
                'contact_info': 'Your Contact Information',
                'oops': 'Oops',
                'wrong': 'Something went wrong!',
            },
            investor_relations: {
                'investor': 'Investor',
                'relations': 'Relations',
                'name': 'Turkish International Investment Bank',
                'trust': 'People trust us',
                'description': 'This section provides shareholders, analysts, the media and other interested parties with\n' +
                    '                            relevant information, enabling a transparent assessment of the company\'s value.',
                '': ''
            },
            login: {
                'login': 'Login',
                'e_mail': 'E-mail/Username',
                'password': 'Password',
                'dont_have_account': 'If you don\'t have an account',
                'sign_up': 'sign up',
                'all_rights': '© 2022 Turkish International Investment Bank Limited. All rights reserved.',
                'back': 'back',
                'terms_and_conditions': 'Terms and Conditions',
                'confirm': 'Confirm'
            },
            personal: {
                'exit': 'EXIT',
                'details': 'Account Details',
                'statement': 'Statement',
                'swift': 'SWIFT/SEPA Transfer',
                'exchange': 'Exchange Transfer',
                'internal': 'Internal Transfer',
                'transfer': 'TRANSFER',
                account_details: {
                    'title': 'Client Details',
                    'id': 'Client ID:',
                    'company_name': 'Company name:',
                    'client_name': 'Client name:',
                    'contact_details': 'Contact details:',
                    'acc_num': 'Account number',
                    'acc_type': 'Account type',
                    'currency': 'Currency',
                    'acc_balance': 'Account Balance',
                    'sec_deposit': 'Security Deposit',
                },
                statement_page: {
                    'title': 'Your Account',
                    'acc_num': 'Account number',
                    'currency': 'Currency',
                    'filter_title': 'Filter By The Account',
                    'date': 'Date',
                    'transfer_type': 'Transfer type',
                    'payment': 'Payment',
                    'transaction_number': 'Transaction number',
                    'debit': 'Debit',
                    'credit': 'Credit',
                    'status': 'Status',
                    'balance': 'Balance',
                },
                swift_page: {
                    'transactions_details': 'Transaction Details',
                    'swift_code': 'SWIFT Code',
                    'bank_name': 'Bank Name',
                    'country': 'Country',
                    'city': 'City',
                    'benef_address': 'Beneficiary Address',
                    'benef_name': 'Beneficiary Name',
                    'acc_num': 'Account number',
                    'bank_address': 'Bank Address',
                    'transfer_details': 'Transfer Details',
                    'amount': 'Amount',
                    'other': 'Other Details',
                    'purpose': 'Purpose of transfer',
                    'transfer': 'TRANSFER',
                    'i_agree_en': 'I agree with Turkish International Investment Bank',
                    'terms_en': 'Terms and Conditions',
                    'i_agree_tu_p1': '',
                    'terms_tu': '',
                    'i_agree_tu_p2': '',
                    'i_agree_ru_p2': '',
                    'i_agree_ru_p1': '',
                    'terms_ru': ''
                },
                exchange_and_internal_page: {
                    'exchange_title': 'Convert Currency and/or Initiate an Exchange Transfer',
                    'from_acc': 'From Account',
                    'to_acc': 'To Account',
                    'select': 'Select account',
                    'amount': 'Amount',
                    'payment_ref': 'Payment Reference',
                    'internal_title': 'Initiate an Internal Transfer'
                }
            },
            api_guide: {
                'api': 'API',
                'guide': 'Guide',
                'start': 'Start using',
                'it_now': 'it now!',
                'difficulties': 'If you have any difficulties, please contact us',
                'json': 'Parameters are passed in JSON format',
                'headers_block': 'Headers block is required for all types of requests',
                'example': 'Example:',
                'available_endpoints': 'Available endpoints',
                'log_in': 'Log In',
                'response': 'Response',
                'tool_signature_request': 'Tool Signature Request',
                'transaction_status_request': 'Transaction status request',
                'refund': 'Refund',
                'transaction_info': 'Transaction info',
                'transactions_list': 'Transactions list:'
            }
        }
    },
    ru: {
        translation: {
            main: {
                'turkey': 'ТУРЕЦКИЙ',
                'international': 'МЕЖДУНАРОДНЫЙ',
                'investment': 'ИНВЕСТИЦИОННЫЙ',
                'bank': 'БАНК',
                'login': 'логин',
                'sign_up': 'открыть счёт',
                'contact_us': 'свяжитесь с нами',
                'investor_relations': 'Investor relations',
                'banking': 'Финансовые услуги',
                'savings_account': 'Депозитный счёт',
                'debit_cards': 'Платёжные карты',
                'e_money': 'Международные платежи',
                'about_us': 'О нас',
                'api_guide': 'API guide',
                'desc_title': 'Полный спектр банковских услуг для физических и юридических лиц',
                'desc_subtitle': 'Опытность и профессионализм. Оптимальная строгость банковского надзора',
                'open_account': 'Открыть счёт',
                'banking_service': 'Банковские услуги',
                'banking_service_content': 'Полный спектр банковских услуг для физических и юридических лиц. Опытность и профессионализм нашей команды. Оптимальная строгость банковского надзора. Мультивалютные счета для международных платежей и депозитов  с  высокой  доходностью. Международные платёжные карты.  Быстрые международные денежные  переводы.  Высокий уровень безопасности и защиты данных. Полный набор инструментов для дистанционного банковского обслуживания. Прямая связь с криптоактивами. Круглосуточная служба поддержки. Наша работа - это Ваш прогресс!',
                'universal_personal_accounts': 'Расчётный счёт',
                'universal_personal_accounts_desc': 'Турецкий Международный Инвестиционный Банк предоставляет возможность открыть расчетный счёт онлайн.  Мы приспособим личный кабинет каждого пользователя, учитывая его потребности и пожелания. Наши универсальные расчётные счета обладают всеми необходимыми свойствами, чтобы упростить и оптимизировать работу с Вашими финансами. Клиенты Турецкого Международного Инвестиционного Банка имеют доступ к самым современным технологиям банковского обслуживания и управления инвестиционным портфелем. Мы стремимся быть лидерами в банковской сфере.',
                'fueling_your_e_commerce': 'Поддержка онлайн бизнеса',
                'fueling_your_e_commerce_desc': 'Турецкий Международный Инвестиционный Банк предоставляет всестороннюю поддержку при запуске и управлении онлайн бизнеса наших клиентов. Мы предоставляем мультивалютные счета, широкую сеть банков корреспондентов и надёжные международные деловые контакты. Наша команда ИТ-экспертов постоянно модернизирует системы, которые позволяют управлять самыми сложными бизнес-моделями онлайн торговли, выводя их на новый международный уровень стабильности и безопасности.',
                'acquiring_services': 'Эквайринг',
                'acquiring_services_desc_p1': 'Турецкий Международный Инвестиционный Банк технологически поддерживает одну из главных своих услуг  - эквайринг.\n' +
                    'Обработка платёжных поручений должна быть точна и своевременна как часы, поскольку от этого зависит точность и скорость проведения банковской операции. Благодаря нам Вы сможете предложить своим партнёрам несколько форм денежных переводов.',
                'acquiring_services_desc_p2': 'Наши клиенты могут рассчитывать на:',
                'acquiring_services_desc_p3': '- Гарантированную безопасность активов и личных данных;',
                'acquiring_services_desc_p4': '- Квалифицированную техническую поддержку 24/7;',
                'acquiring_services_desc_p5': '- Возможность принимать и обрабатывать платежи в криптовалютах;',
                'acquiring_services_desc_p6': '- Интуитивный пользовательский интерфейс.',
                'saving': 'Депозитный',
                'account': 'счёт',
                'deposit_with': 'Депозитные сертификаты ТМИБ',
                'investment_bank': '',
                'saving_desc': 'Турецкий Международный Инвестиционный Банк (ТМИБ) выплачивает до 7% по депозитным\n' +
                    'сертификатам в долларах США и евро. Никаких\n' +
                    'ограничений на вклады, ясная и чёткая система ежеквартальных выплат дивидендов. Единственное, что\n' +
                    'Вам необходимо, это быть клиентом ТМИБ.',
                'debit_cards_block_title': 'Платёжные карты',
                'debit_cards_block_subtitle': 'Платёжные карты Visa® и UnionPay для Вас и Ваших партнёров',
                'debit_cards_block_desc': 'Виртуальная или пластиковая платёжная карта ТМИБ предоставит Вам доступ к ' +
                    'Вашим финансам в любое время и в любом месте. Наша система 3D защиты Ваших онлайн платежей убережёт ' +
                    'Ваши финансы от мошенников. В любой точке мира Вы можете воспользоваться банкоматом или оплатить ' +
                    'Вашу покупку в магазине. Ваши финансы не имеют границ с платёжными карточками Турецкого Международного ' +
                    'Инвестиционного Банка.',
                'debit_cards_block_desc2': ' Откройте счёт сегодня в ТМИБ и почувствуйте свободу Ваших денег!',
                'e_money_title_1': 'Решения для Вашего бизнеса',
                'e_money_desc_1': 'Турецкий Международный Инвестиционный Банк - финансовая организация международного ' +
                    'масштаба с индивидуальным подходом к каждому клиенту. Неважно, если Вы большая корпорация или ' +
                    'начинающий малый бизнес - мы всегда найдём оптимальное решение для ваших финансов! ТМИБ - это и ' +
                    'международные мультивалютные переводы, и представительства во многих странах, и банкомат, который ' +
                    'Вы всегда найдёте рядом с Вами. ТМИБ - это союз стабильности и высокотехнологичной безопасности Ваших счетов на международном финансовом рынке!',
                'e_money_title_2': 'SEPA, SWIFT',
                'e_money_desc_2': 'Мы предлагаем широкий выбор разных типов расчётных счетов в различных валютах для платежей в европейской системе SEPA и международной системе SWIFT.\n' +
                    'Благодаря нашему интернет-банкингу Вам не нужен будет личный банкир. В режиме онлайн Вы сможете ' +
                    'решить все вопросы, связанные с Вашими финансами: осуществить денежный перевод, заказать платёжную ' +
                    'карту или выбрать услуги, которые полностью удовлетворят потребности Вашего бизнеса. И всё это 24/7!',
                'privacy': '© 2022 Турецкий Международный Инвестиционный Банк Лимитед. Все права защищены.',
                'terms': 'Условия и Положения',
                'send': 'отправить',
                'valid_email': 'Введите корректный Email',
                'all_fields': 'Заполните все поля'
            },
            calc: {
                'title': 'Расчёт дивидендов: ',
                'deposit_amount': 'сумма вклада',
                'period': 'период',
                'month': 'мес.',
                'currency': 'валюта',
                'open_account': 'Открыть счёт',
                'interest': 'Проценты',
                'in': 'за',
                'month_2': 'месяцев',
                'calc_sentence_1': 'сумма Вашего вклада составит ',
                'calc_sentence_2': '',
            },
            contact: {
                'contact': 'Наши',
                'us': 'контакты',
                'reach_us': 'Вы можете связаться с нами',
                'welcome_to': 'Добро пожаловать в Турецкий Международный Инвестиционный Банк',
                'name': 'Имя',
                'phone': 'Номер телефона',
                'message': 'Сообщение',
                'cyprus_title': 'Представительство на Кипре:',
                'cyprus_address_1': 'ул. Спироса Киприану, 57,',
                'cyprus_address_2': 'Бизнес-центр Bybloserve,',
                'cyprus_address_3': 'Ларнака 6051, Кипр',
            },
            popup: {
                'title': 'Send a request',
                'subtitle': 'Наши специалисты вскоре свяжутся с Вами',
                'name': 'Имя',
                'phone': 'Номер телефона',
                'email': 'E-mail',
                'send': 'Отправить',
                'success': 'Успешно!',
                'ok': 'OK',
                'title_opening': 'Запрос на Открытие Онлайн Счёта',
                'download': 'Скачать Заявление на Открытие Счёта',
                'personal': 'Заявление для открытия личного счета',
                'corporate': 'Заявление для открытия корпоративного счета',
                'upload': 'Загрузить Заполненное и Подписанное Заявление',
                'add': 'Добавить',
                'contact_info': 'Ваша контактная информация',
                'oops': 'Упс',
                'wrong': 'Что-то пошло не так!',
            },
            investor_relations: {
                'investor': 'Investor',
                'relations': 'Relations',
                'name': 'Turkish International Investment Bank',
                'trust': 'People trust us',
                'description': 'This section provides shareholders, analysts, the media and other interested parties with\n' +
                    '                            relevant information, enabling a transparent assessment of the company\'s value.',
                '': ''
            },
            login: {
                'login': 'Логин',
                'e_mail': 'E-mail/Имя пользователя',
                'password': 'Пароль',
                'dont_have_account': 'Если у Вас нет счёта',
                'sign_up': 'зарегистрируйтесь',
                'all_rights': '© 2022 Турецкий Международный Инвестиционный Банк Лимитед. Все права защищены.',
                'back': 'назад',
                'terms_and_conditions': 'Условия и Положения',
                'confirm': 'Подтвердить'
            },
            personal: {
                'exit': 'Выход',
                'details': 'Детали Аккаунта',
                'statement': 'Выписка По Счёту',
                'swift': 'Трансфер SWIFT/SEPA',
                'exchange': 'Валютный Трансфер',
                'internal': 'Внутренний Трансфер',
                'transfer': 'ТРАНСФЕР',
                account_details: {
                    'title': 'Детали Клиента',
                    'id': 'ID Клиента:',
                    'company_name': 'Название Компании:',
                    'client_name': 'Имя Клиента:',
                    'contact_details': 'Контактная информация:',
                    'acc_num': 'Номер счёта',
                    'acc_type': 'Тип счёта',
                    'currency': 'Валюта',
                    'acc_balance': 'Баланс Счёта',
                    'sec_deposit': 'Залоговый Депозит',
                },
                statement_page: {
                    'title': 'Ваш Счёт',
                    'acc_num': 'Номер счёта',
                    'currency': 'Валюта',
                    'filter_title': 'Выберите счёт',
                    'date': 'Дата',
                    'transfer_type': 'Тип трансфера',
                    'payment': 'Назначение',
                    'transaction_number': 'Номер транзакции',
                    'debit': 'Дебет',
                    'credit': 'Кредит',
                    'status': 'Статус',
                    'balance': 'Баланс',
                },
                swift_page: {
                    'transactions_details': 'Детали Транзакции',
                    'swift_code': 'SWIFT Код',
                    'bank_name': 'Название Банка',
                    'country': 'Страна ',
                    'city': 'Город ',
                    'benef_address': 'Адрес Получателя',
                    'benef_name': 'Имя Получателя',
                    'acc_num': 'Номер счёта',
                    'bank_address': 'Адрес Банка',
                    'transfer_details': 'Детали Трансфера',
                    'amount': 'Сумма ',
                    'other': 'Другие Детали',
                    'purpose': 'Цель перевода',
                    'transfer': 'Перевести',
                    'i_agree_tu_p1': '',
                    'terms_tu': '',
                    'i_agree_tu_p2': '',
                    'i_agree_ru_p1': 'Я согласен с',
                    'terms_ru': ' Условиями и Положениями ',
                    'i_agree_ru_p2': 'Турецкого Международного Инвестиционного Банка',
                    'i_agree_en': '',
                    'terms_en': '',
                },
                exchange_and_internal_page: {
                    'exchange_title': 'Конвертировать Валюту и/или Начать Валютный Трансфер',
                    'from_acc': 'Со Счёта',
                    'to_acc': 'На Счёт',
                    'select': 'Выбрать счёт',
                    'amount': 'Сумма',
                    'payment_ref': 'Назначение Платежа',
                    'internal_title': 'Начать Внутренний Трансфер'
                }
            },
            api_guide: {
                'api': 'API',
                'guide': 'Guide',
                'start': 'Начните пользоваться',
                'it_now': 'им сейчас!',
                'difficulties': 'Если у Вас возникли затруднения, пожалуйста, свяжитесь с нами',
                'json': 'Parameters are passed in JSON format',
                'headers_block': 'Headers block is required for all types of requests',
                'example': 'Example:',
                'available_endpoints': 'Available endpoints',
                'log_in': 'Log In',
                'response': 'Response',
                'tool_signature_request': 'Tool Signature Request',
                'transaction_status_request': 'Transaction status request',
                'refund': 'Refund',
                'transaction_info': 'Transaction info',
                'transactions_list': 'Transactions list:'
            }
        }
    },
    tr: {
        translation: {
            main: {
                'turkey': 'TÜRKİYE',
                'international': 'ULUSLARARASI',
                'investment': 'YATIRIM',
                'bank': 'BANKASI',
                'login': 'GİRİŞ YAP',
                'sign_up': 'Hesap Açma',
                'contact_us': 'Bi̇ze Ulaşin',
                'investor_relations': 'Yatirimci İli̇şki̇leri̇',
                'banking': 'BANKACILIK',
                'savings_account': 'VADELİ HESAP',
                'debit_cards': 'Bankamati̇k Kartlari',
                'e_money': 'E-PARA',
                'about_us': 'HAKKIMIZDA',
                'api_guide': 'API Kılavuzu',
                'desc_title': 'Genel ve özel bankacılık hizmetlerinin tamamı',
                'desc_subtitle': 'Deneyimli, profesyonel idare ve mükkemel Yönetim Kurulu. Katı ama makul uyumluluk politikaları.',
                'open_account': 'HESAP AÇMA',
                'banking_service': 'Bankacılık Hizmeti',
                'banking_service_content': 'Küresel  ve özel bankacılık sistemlerinin tamamında deneyimli ve profesyonel' +
                    ' yönetim. Katı ama makul uyum politikaları. Küresel ödemeler ve yüksek mevduatlar için çok para birimli ' +
                    'banka hesapları. Evrensel olarak kabul edilen banka kartları. Hızlı küresel para transferleri. ' +
                    'Mükemmel hesap güvenliği ve veri koruması. Çevrimiçi olarak eksiksiz E-Bankacılık araçları paketi. ' +
                    'Kripto varlığına sorunsuz ve kesintisiz bağlantı. Güler yüzlü, yetkin destek personeli. Yaptığımız ' +
                    've Olduğumuz şey bu!',
                'universal_personal_accounts': 'Evrensel kişisel hesaplar',
                'universal_personal_accounts_desc': 'TÜRKİYE ULUSLARARASI YATIRIM BANKASI, çevrimiçi olarak ' +
                    'başvurulup açılabilen kişisel hesaplar sunmaktadır. Kişisel hesabınızın özellik paketi , ihtiyaçlarınıza ' +
                    'mükemmel uyacak şekilde hazırlanacaktır .Genel bankacılık hesaplarımız, tüm ailenizin günlük finansal ' +
                    'yaşamını basitleştirmek ve düzene sokmak için ihtiyacınız olan her şeyi sunar. TÜRKİYE ULUSLARASI ' +
                    'YATIRIM BANKASI müşterileri, bankacılık ve yatırım portföylerinin dünya çapındaki sektör ' +
                    'meslektaşlarımız arasında benzeri görülmemiş düzeyde kişiselleştirilmiş yönetimin keyfini çıkarmaktadır.',
                'fueling_your_e_commerce': 'E-ticaretinizi körüklemek',
                'fueling_your_e_commerce_desc': 'TÜRKİYE ULUSLARARASI YATIRIM BANKASI, uluslararası e-ticaretinizi ' +
                    'başlatmak ve yönetmek için ihtiyacınız olan her şeyi sağlar. Birden fazla para birimi yönetme, ' +
                    'dünyanın önemli bölgelerindeki destek yerleri, muhabir banka ağımız ve uzman Bilgi Teknolojileri ' +
                    'ekibi, aradığınız istikrarlı ve makul fiyatlı bir ortamda en zorlu e-ticaret misyonlarını başlatmak ' +
                    've işletmek için tam olarak ihtiyacınız olan şeydir.',
                'acquiring_services': 'Hizmet edinme',
                'acquiring_services_desc_p1': 'Türkiye Uluslararası Yatırım Bankası, sitenin en önemli hizmetlerinden birinin teknik desteğini gerçekleştirecek - Hizmet Edinme. Ödemelerin ne kadar hızlı ve doğru bir şekilde işleme alınacağına bağlı olduğundan sitedeki ödemelerin kabul edilmesi saat gibi çalışmalıdır. Yeni müşterilerinize çeşitli para çekme yöntemleri sunabileceksiniz.',
                'acquiring_services_desc_p2': 'Bizimle işbirliği yapmak:',
                'acquiring_services_desc_p3': '- Varlıkların ve müşterilerin kişisel verilerinin güvenliğinden emin olabilirsiniz.',
                'acquiring_services_desc_p4': '- 7/24 nitelikli teknik destek.',
                'acquiring_services_desc_p5': '- Kripto para biriminde ödeme kabul etme ve işleme imkanı.',
                'acquiring_services_desc_p6': '- Sezgisel arayüz.',
                'saving': 'Vadeli',
                'account': 'Hesap',
                'deposit_with': 'TÜRKİYE ULUSLARARASI YATIRIM BANKASI',
                'investment_bank': 'ile Mevduat Hesabı',
                'saving_desc': 'TÜRKİYE ULUSLARARASI YATIRIM BANKASI, Vadeli Hesaplarındaki Mevduat Sertifikalarında ' +
                    'USD ve EUR cinsinden %7’e kadar ödeme yapmaktadır. Hiçbir koşula bağlı kalmadan, sadece düz ' +
                    've basit üç ayda bir faiz ödemeleri. Yararlanabilmek için mevcut bir TÜRKİYE ULUSLARARASI YATIRIM ' +
                    'BANKASI hesabına sahip olmanız yeterli.',
                'debit_cards_block_title': 'Banka Kartları',
                'debit_cards_block_subtitle': 'Sizin ve iş ortaklarınız için Visa® ve UnionPay banka kartları',
                'debit_cards_block_desc': 'TUYB sanal veya banka kartı, paranıza istediğiniz zaman ve her yerden ' +
                    'erişmenizi sağlayacaktır. Çevrimiçi ödemeleriniz için 3D güvenlik sistemimiz,  mal varlığınızı ' +
                    'dolandırıcılardan koruyacaktır. Herhangi bir ATM kullanabilir veya satın alma işleminiz için dünya ' +
                    'çapındaki herhangi bir mağazada ödeme yapabilirsiniz. Finansmanınızın Türkiye Uluslararası Yatırım ' +
                    'Bankası ödeme kartlarıyla sınırı yoktur.',
                'debit_cards_block_desc2': 'Bugün TUYB\'da bir hesap açın ve paranızın özgürlüğünü hissedin!',
                'e_money_title_1': 'İşiniz için, sizin için',
                'e_money_desc_1': 'TÜRKİYE ULUSLARARASI YATIRIM BANKASI, kişisel bir temaya sahip küresel bir finans ' +
                    'kurumudur. Küçük, büyük bütün işleriniz için finansal bir çözüm olabilir! Hem uluslararası hem de ' +
                    'çok para birimliyiz ve köşedeki ATM kadar yerliyiz. Tuğla ve harç istikrarını bulut tabanlı e-cüzdanlarımızın ' +
                    'yüksek teknoloji güvenliği ile birleştirerek, küresel Bankanızın olmasını istediğiniz her şeyiz!',
                'e_money_title_2': 'SEPA, SWIFT',
                'e_money_desc_2': 'Avrupa SEPA ve uluslararası SWIFT sistemlerine erişimi olan çeşitli para birimlerinde ' +
                    'hesaplar sunuyoruz. Sanal ve ön ödemeli banka kartları, hızlı eşler arası transferler ve çevrimiçi ' +
                    'olarak yönetilen diğer birçok harika özellik de dahil olmak üzere çok yönlü kişisel e-bankacılık ' +
                    'çözümlerimiz sayesinde herkesin günlük ihtiyaçları karşılanırken, yüksek net gelire sahip müşterilerimiz ' +
                    'için bireysel bankacılık hizmeti sağlanmaktadır.',
                'privacy': '© 2022 TÜRKİYE ULUSLARARASI YATIRIM BANKASI. Tüm hakları saklıdır. ',
                'terms': 'Hükümler ve Koşullar.',
                'send': 'GÖNDER',
                'valid_email': 'Geçerli bir e-posta adresi girin',
                'all_fields': 'Bütün alanları doldurunuz.'
            },
            calc: {
                'title': 'Faiz miktarını hesaplayın',
                'deposit_amount': 'Mevduat tutarı',
                'period': 'süre',
                'month': 'ay',
                'currency': 'para birimi',
                'open_account': 'HESAP AÇMA',
                'interest': 'Faiz',
                'in': '',
                'month_2': '',
                'calc_sentence_1': 'ayda mevuatınızdan',
                'calc_sentence_2': 'faiz kazanabilirsiniz',
            },
            contact: {
                'contact': 'BİZE ',
                'us': 'ULAŞIN',
                'reach_us': 'BİZE ULAŞABİLİRSİNİZ',
                'welcome_to': 'Türkiye Uluslararasi Yatirim Bankasina Hoş Geldiniz',
                'name': 'İsim',
                'phone': 'Telefon',
                'message': 'Mesaj',
                'cyprus_title': 'Kıbrıs Temsilciliği:',
                'cyprus_address_1': '57 Spyrou Kipriyanu Caddesi,',
                'cyprus_address_2': 'Bybloserve İş Merkezi,',
                'cyprus_address_3': 'Larnaka 6051, Kıbrıs',
            },
            popup: {
                'title': 'İstek gönder',
                'subtitle': 'En kısa zamanda uzmanlarımız kişisel bir hesap oluşturmak için sizinle iletişime geçecektir.',
                'name': 'İsim',
                'phone': 'Telefon',
                'email': 'E-posta',
                'send': 'GÖNDER',
                'success': 'Başarılı!',
                'ok': 'Tamam',
                'title_opening': 'Online Hesap Açma Talebi',
                'download': 'Hesap Açma Formunu indirin',
                'personal': 'Kişisel Hesap Açma Formu',
                'corporate': 'Kurumsal Hesap Açma Formu',
                'upload': 'Doldurulmuş ve İmzalanmış Formu yükleyin',
                'add': 'EKLE',
                'contact_info': 'İletişim Bilgileriniz',
                'oops': 'Eyvah',
                'wrong': 'Bir şeyler ters gitti!',
            },
            investor_relations: {
                'investor': 'YATIRIMCI',
                'relations': 'İLİŞKİLERİ',
                'name': 'TÜRKİYE ULUSLARARASI YATIRIM BANKASI',
                'trust': 'İnsanlar bize güveniyor',
                'description': 'Bu bölüm, hissedarlar, analistler, medya ve diğer ilgili taraflara ilgili bilgileri ' +
                    'sağlayarak şirketin değerinin şeffaf bir şekilde değerlendirilmesini sağlar.',
                '': '',
            },
            login: {
                'login': 'GİRİŞ',
                'e_mail': 'E-posta/Kullanıcı Adı',
                'password': 'Şifre',
                'dont_have_account': 'Eğer hesabınız yoksa',
                'sign_up': 'kayıt olun',
                'all_rights': '© 2022 TÜRKİYE ULUSLARARASI YATIRIM BANKASI. Tüm hakları saklıdır.',
                'back': 'Geri',
                'terms_and_conditions': 'Hükümler ve Koşullar',
                'confirm': 'ONAY'
            },
            personal: {
                'exit': 'ÇIKIŞ',
                'details': 'Hesap Detayları',
                'statement': 'Hesap Özeti',
                'swift': 'SWIFT/SEPA Transferi',
                'exchange': 'Takas İşlemi',
                'internal': 'İç Transfer',
                'transfer': 'TRANSFER ET',
                account_details: {
                    'title': 'Müşteri Bilgileri',
                    'id': 'Müşteri Kimliği:',
                    'company_name': 'Şirket Adı:',
                    'client_name': 'Müşteri Adı:',
                    'contact_details': 'İletişim Bilgileri:',
                    'acc_num': ' Hesap Numarası',
                    'acc_type': 'Hesap Türü',
                    'currency': 'Para Birimi',
                    'acc_balance': 'Hesap Bakiyesi',
                    'sec_deposit': 'Depozito',
                },
                statement_page: {
                    'title': 'Hesaplarınız',
                    'acc_num': 'Hesap Numarası',
                    'currency': 'Para Birimi',
                    'filter_title': 'Hesaba Göre Filtrele',
                    'date': 'Tarih',
                    'transfer_type': 'Gönderim Türü',
                    'payment': 'Ödeme',
                    'transaction_number': 'İşlem Numarası',
                    'debit': 'Borç',
                    'credit': 'Miktar',
                    'status': 'Durum',
                    'balance': 'Bakiye',
                },
                swift_page: {
                    'transactions_details': 'İşlem Detayları',
                    'swift_code': 'SWIFT Kodu',
                    'bank_name': 'Banka Adı',
                    'country': 'Ülke',
                    'city': 'Şehir',
                    'benef_address': 'Yararlanıcı Adresi',
                    'benef_name': 'Yararlanıcı Adı',
                    'acc_num': 'Hesap Numarası',
                    'bank_address': 'Banka Adresi',
                    'transfer_details': 'Transfer Detayları',
                    'amount': ' Miktar',
                    'other': 'Diğer Detaylar',
                    'purpose': 'Gönderim Amacı',
                    'i_agree_en': '',
                    'terms_en': '',
                    'i_agree_tu_p1': 'Türkiye Uluslararası Yatırım Bankasının',
                    'terms_tu': 'Hüküm ve Koşullarını',
                    'i_agree_tu_p2': 'kabul ediyorum.',
                    'i_agree_ru_p2': '',
                    'i_agree_ru_p1': '',
                    'terms_ru': ''
                },
                exchange_and_internal_page: {
                    'exchange_title': 'Para Birimini Dönüştürme ve/veya Değişim Transferi Başlatma',
                    'from_acc': 'Hesaptan',
                    'to_acc': 'Hesaba',
                    'select': 'Hesap seç',
                    'amount': 'Miktar ',
                    'payment_ref': 'Ödeme Referansı',
                    'internal_title': 'Dahili Aktarım Başlatma'
                }
            },
            api_guide: {
                'api': 'API',
                'guide': 'Kılavuzu',
                'start': 'Kullanmaya hemen ',
                'it_now': 'başlayın',
                'difficulties': 'Herhangi bir zorluk yaşarsanız, lütfen bizimle iletişime geçin',
                'json': 'Parameters are passed in JSON format',
                'headers_block': 'Her tür talep için başlık bloğu gereklidir',
                'example': 'Örnek:',
                'available_endpoints': 'Kullanılabilir uç noktalar',
                'log_in': 'Giriş yapmak',
                'response': 'Yanıt:',
                'tool_signature_request': 'Araç İmza Talebi',
                'transaction_status_request': 'İşlem durumu isteği',
                'refund': 'İade',
                'transaction_info': 'İşlem bilgileri',
                'transactions_list': 'İşlem listesi:'
            }
        }
    }
};

// const initLang = () => {
//     if (localStorage.getItem('lang')) {
//         return localStorage.getItem('lang')
//     } else return 'en'
// }

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: 'en', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
        // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
        // if you're using a language detector, do not define the lng option

        interpolation: {
            escapeValue: false,// react already safes from xss
            keySeparator: "."

        }
    });

export default i18n;
