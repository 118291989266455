import React from "react";
import { Button, Stack } from "react-bootstrap";

function Confirm({ data }) {
  return (
    <div className="p-5 d-flex justify-content-center align-item-center text-center">
      <div>
        {data?.title && data?.title !== "" && <h1>{data?.title}</h1>}
        {data?.message && data?.message !== "" && (
          <p className="pt-3">{data?.message}</p>
        )}
        <Stack
          direction="horizontal"
          gap={3}
          className="justify-content-center align-item-center pt-3"
        >
          <Button
            autoFocus={false}
            className="px-4 py-2 btn"
            onClick={() => data.onApprove()}
            variant="outline-dark"
          >
            {data?.actionText?.yes || "Yes"}
          </Button>
          <Button
            className="px-4 py-2"
            onClick={() => data.onDeny()}
            variant="dark"
          >
            {data?.actionText?.no || "No"}
          </Button>
        </Stack>
      </div>
    </div>
  );
}

export default Confirm;
