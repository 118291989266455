import React, {useState} from 'react';
import s from "./InvestorRelations.module.css";
import mainBg from "../../assets/img/investor/bg.png";
import {API} from "../../utils/api/api";
import MyPopupSuccess from "../../utils/MyPopupSuccess/MyPopupSuccess";
import {withTranslation} from "react-i18next";
import {t} from "i18next";

const InvestorRelations = () => {

    const [data, setData] = useState({
        firstName: "",
        phone: "",
        message: "",
    });

    const [popupVisible, setPopupVisible] = useState(false)

    const dataForSend = {
        project: "turkey",
        subject: "Hello!",
        data: data
    }

    const sendMessage = async () => {
        let response = await API.send(dataForSend);
        console.log(response)
        setPopupVisible(true)
        if (response.status === 200) {
            setData({
                firstName: '',
                message: '',
                phone: '',
            })
        }
    }

    return (
        <div>
            <MyPopupSuccess visible={popupVisible} setVisible={setPopupVisible}/>
            <div className={s.main}>
                <img src={mainBg} alt="" className={s.main_bg}/>

                { localStorage.getItem('lang') === 'en'
                    ? <div className={s.blur}>
                        <div className={s.main_text}>
                            {t('investor_relations.investor')}
                            <div className={s.main_text2}>
                                {t('investor_relations.relations')}
                            </div>
                        </div>
                    </div>
                    : <div className={s.blur}>
                        <div className={s.main_text}>
                            {t('investor_relations.investor')}
                            <div className={s.main_text2_tr}>
                                {t('investor_relations.relations')}
                            </div>
                        </div>
                    </div>
                }
            </div>

            <div className={s.main_mobile}>
                <div className={s.main_text}>
                    {t('investor_relations.investor')} <br/>
                    {t('investor_relations.relations')}
                </div>
            </div>

            <div className={s.contact_us_content}>
                <div className={s.left}>
                    <div className={s.title}>
                        {t('investor_relations.name')}
                    </div>
                    <div className={s.welcome_block}>
                        <div className={s.welcome}>
                            {t('investor_relations.trust')}
                        </div>
                        <div className={s.description}>
                            {t('investor_relations.description')}
                        </div>
                    </div>

                </div>
                <div className={s.right}>
                    <div className={s.inputs_block}>
                        <div className={s.input_name_block}>
                            <div className={s.input_name}>
                                {t('contact.name')}
                            </div>
                            <input className={s.my_input}
                                   type="text"
                                   value={data.firstName}
                                   onChange={(e) => {
                                       setData({...data, firstName: e.currentTarget.value})
                                   }}
                            />
                        </div>
                        <div>
                            <div className={s.input_name}>
                                {t('contact.phone')}
                            </div>
                            <input className={s.my_input}
                                   type="text"
                                   value={data.phone}
                                   onChange={(e) => {
                                       setData({...data, phone: e.currentTarget.value})
                                   }}
                            />
                        </div>
                    </div>
                    <div>
                        <div className={s.input_name}>{t('contact.message')}</div>
                        <textarea className={s.my_textarea}
                                  value={data.message}
                                  onChange={(e) => {
                                      setData({...data, message: e.currentTarget.value})
                                  }}
                        />
                    </div>
                    <button className={s.my_btn}
                            onClick={sendMessage}
                    >
                        {t('popup.send')}
                    </button>

                </div>
            </div>
        </div>
    );
};

export default withTranslation()(InvestorRelations)
