import React from 'react';
import s from './MyPopupSuccess.module.css'
import closePopup from "../../assets/img/Close.png";
import {withTranslation} from "react-i18next";
import {t} from "i18next";

const MyPopupSuccess = ({visible, setVisible}) => {

    const rootClasses = [s.myModal]

    if (visible) {
        rootClasses.push(s.active)
    }

    return (
        <div className={rootClasses.join(' ')} onClick={() => setVisible(false)}>
            <div className={s.myModalContent} onClick={(e) => e.stopPropagation()}>
                <div className={s.popup_title}>
                    {t('popup.success')}
                </div>

                <div className={s.flex}>
                    <button className={s.my_btn} onClick={() => setVisible(false)}>{t('popup.ok')}</button>
                </div>
            </div>
        </div>
    );
};

export default withTranslation()(MyPopupSuccess)
