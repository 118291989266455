import React, { useState } from "react";
import s from "./Calculate.module.css";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import MyPopup from "../../utils/MyPopup/MyPopup";
import {withTranslation} from "react-i18next";
import {t} from "i18next";

const Calculate = () => {
  const [rangeValue, SetRangeValue] = useState(10000);
  const [currency, SetCurrency] = useState("EUR");
  const [month, SetMonth] = useState(6);
  const [popupIsVisible, setPopupIsVisible] = useState(false)

  const marks = {
    10000: {
      style: {left: '6%'},
      label: "10 000",
    },
    1250000: " ",
    2500000: " ",
    3750000: " ",
    5000000: "5 000 000",
    6250000: " ",
    7500000: " ",
    8750000: " ",
    10000000: {
      style: { whiteSpace: "nowrap", left: '89%' },
      label: "10 000 000",
    },
  };

  const CalculateFees = () => {
    if (month === 6) {
      return Math.ceil(rangeValue * 1.035)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    } else {
      return Math.ceil(rangeValue * 1.07)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }
  };

  return (
    <div className={s.calculate_wrapper}>
      <MyPopup visible={popupIsVisible} setVisible={setPopupIsVisible}/>
      <div className={s.title}>{t('calc.title')}</div>
      <div className={s.range_wrapper}>
        <div className={s.range}>
          <div className={s.range_title}>{t('calc.deposit_amount')}</div>
          <div className={s.deposit_amount}>{rangeValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}</div>
          <div className={s.slidecontainer}>
            <Slider
              trackStyle={{ background: "#2B3F75", height: "2px" }}
              railStyle={{ background: "#AEBABA", height: "2px" }}
              handleStyle={{
                cursor: "pointer",
                background: "#2B3F75",
                borderColor: "#2B3F75",
              }}
              dotStyle={{
                border: "1px solid #AEBABA",
                height: "10px",
                width: "1px",
                borderRadius: "initial",
                bottom: "initial",
              }}
              defaultValue={10000}
              className={s.slider}
              marks={marks}
              min={10000}
              max={10000000}
              value={rangeValue}
              onChange={(e) => {
                SetRangeValue(e);
              }}
              style={{ height: "4px" }}
              draggableTrack
            />
          </div>
        </div>
        <div className={s.period}>
          <div className={s.range_title}>{t('calc.period')}</div>
          <div className={s.month_block}>
            <div
              style={{ marginRight: "15px" }}
              className={month === 6 ? s.active : s.month}
              onClick={(e) => {
                SetMonth(6);
              }}
            >
              <span className={month === 6 ? s.month_num_active : s.month_num}>
                6
              </span>{" "} <br/>
              {t('calc.month')}
            </div>
            <div
              className={month === 12 ? s.active : s.month}
              onClick={(e) => {
                SetMonth(12);
              }}
            >
              <span className={month === 12 ? s.month_num_active : s.month_num}>
                12
              </span>{" "} <br/>
              {t('calc.month')}
            </div>
          </div>
        </div>
        <div className={s.currency}>
          <div className={s.range_title}>{t('calc.currency')}</div>
          <div>
            <select
              value={currency}
              className={s.my_select}
              onChange={(e) => {
                SetCurrency(e.currentTarget.value);
              }}
            >
              <option className={s.my_select_option} value="EUR">
                EUR
              </option>
              <option className={s.my_select_option} value="USD">
                USD
              </option>
            </select>
          </div>
        </div>
      </div>
      <div className={s.interest}>
        {t('calc.interest')} <span className={s.interest_value}>7%</span>
      </div>
      <div className={s.interest_desc}>
        {t('calc.in')} <span className={s.bold}>{`${month} ${t('calc.month_2')}`} </span>
        {t('calc.calc_sentence_1')}
        <span className={s.bold}>
          {" "}
          {currency === "EUR" ? <span>€</span> : <span>$</span>} {CalculateFees()} {" "}
        </span>
        {t('calc.calc_sentence_2')}
      </div>
      <div className={s.btn_block}>
          <button className={s.my_btn} onClick={() => {setPopupIsVisible(true)}}>{t('calc.open_account')}</button>
      </div>
    </div>
  );
};

export default withTranslation()(Calculate)

