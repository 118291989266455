export class Alert {
  static popup;
  static type;
  static title;
  static message;
  static actionText;
  static onClose;
  static onApprove;
  static onDeny;

  static setPopup(popup) {
    this.popup = popup;
  }

  static show(type, title = "", message = "", onClose = () => {}) {
    if (this.popup) {
      this.type = type;
      this.title = title;
      this.message = message;
      this.onClose = onClose;
      this.popup.open();
    }
  }

  static confirm(
    title = "",
    message = "",
    actionText = { yes: "Yes", no: "No" },
    onApprove = () => {},
    onDeny = () => {}
  ) {
    if (this.popup) {
      this.type = "confirm";
      this.title = title;
      this.message = message;
      this.actionText = actionText;
      this.onApprove = onApprove;
      this.onDeny = onDeny;
      this.popup.open();
    }
  }

  static hide(callback) {
    this.popup.close();
    if (typeof callback === "function") {
      callback();
    }
  }

  static getInfo() {
    return {
      type: this.type,
      title: this.title,
      message: this.message,
      actionText: this.actionText,
      onClose: () => this.hide(this.onClose),
      onApprove: () => this.hide(this.onApprove),
      onDeny: () => this.hide(this.onDeny),
    };
  }
}
