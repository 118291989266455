import React, {useState} from "react";
import s from "./Header.module.css";
import logo from "../../assets/img/logo.png";
import LoginBtnMobile from "../../assets/img/Log_in_mob.png";
import burgerMenu from "../../assets/img/Menu.png";
import logoTrWhite from "../../assets/img/logo_tr_white.png";
import login from "../../assets/img/Log_in.png";
import {useDispatch} from "react-redux";
import {Link, NavLink, useLocation} from "react-router-dom";
import {Link as ScrollLink} from "react-scroll";
import {Helper} from "../../utils/helper";
import {authSlice} from "../../Reducers/AuthReducer";
import {userSlice} from "../../Reducers/UserReducer";
import MyPopup from "../../utils/MyPopup/MyPopup";
import {withTranslation} from "react-i18next";
import i18n from "../../i18n";
import {t} from "i18next";
import Sidebar from '../sidebar/Sidebar';


const Header = ({auth}) => {
    let dispatch = useDispatch();
    const location = useLocation();
    const {logout} = authSlice.actions;
    const {clearState} = userSlice.actions;

    const [popupIsVisible, setPopupIsVisible] = useState(false)
    const [isMenuOpen, setIsMenuOpen] = useState(false)

    const shouldHide = () => {
        return (
            location.pathname === "/login" ||
            location.pathname === "/signup" ||
            location.pathname.includes("personal")
        );
    };

    const isScrollOrIsLink = () => {
        return (
            location.pathname === "/contact-us" ||
            location.pathname === "/api-guide" ||
            location.pathname === "/investor-relations"
        );
    };

    const handleLogout = () => {
        dispatch(logout())
        dispatch(clearState())
    };

    const handleChangeLanguage = (e) => {
        const lang = e.target.textContent.toLowerCase()
        i18n.changeLanguage(lang);
        localStorage.setItem('lang', lang)
        window.location.reload()
    }

    // check should hide
    if (shouldHide()) {
        return <div></div>;
    }

    const getLogo = () => {
        if (localStorage.getItem('lang') === 'tr') {
            return <img src={logoTrWhite} alt="logo" className={s.logo}/>
        } else {
            return <img src={logo} alt="logo" className={s.logo}/>
        }
    }

    const getLogoMob = () => {
        if (localStorage.getItem('lang') === 'tr') {
            return <img src={logoTrWhite} alt="logo" className={s.logo_mob}/>
        } else {
            return <img src={logo} alt="logo" className={s.logo_mob}/>
        }
    }

    const getStylesForRussian = () => {
        if (localStorage.getItem('lang') === 'ru') {
            return s.link_ru
        } else {
            return ''
        }
    }

    if (isScrollOrIsLink()) {
        return <div>
            <div className={s.headerWrapper} id={'1'}>
                <div className={s.headerContainer}>
                    <div className={s.left}>
                        <Link to="/">
                            { getLogo() }
                        </Link>
                        <Link to="/login">
                            <div className={s.loginButtonBlock}>
                                <img src={login} alt="login"/>
                                <span className={s.login}>{t('main.login')}</span>
                            </div>
                        </Link>
                        <div>
                            <button className={s.signUpButton}
                                    onClick={() => {
                                        setPopupIsVisible(true)
                                    }}
                            >{t('main.sign_up')}
                            </button>
                        </div>
                        <div className={s.lng_block}>
                            <div className={localStorage.getItem('lang') === 'en' ? s.active_lang : s.lng}
                                 onClick={handleChangeLanguage}>
                                EN
                            </div>
                            <div className={localStorage.getItem('lang') === 'ru' ? s.active_lang : s.lng}
                                 onClick={handleChangeLanguage}>
                                RU
                            </div>
                            <div className={localStorage.getItem('lang') === 'tr' ? s.active_lang : s.lng}
                                 onClick={handleChangeLanguage}>
                                TR
                            </div>
                        </div>
                    </div>
                    <div className={s.right}>

                        <NavLink to="/">
                            <button className={s.my_btn}>
                                <div className={s.arr_back}></div>
                                {t('login.back')}
                            </button>
                        </NavLink>

                        <MyPopup visible={popupIsVisible} setVisible={setPopupIsVisible}/>

                        {Helper.checkAuth(auth) ? (
                            <div className={s.dropdown}>
                                <div className={`${s.loginButtonBlock} ${s.dropBtn}`}>
                                    {auth?.user?.name || "Account"}{" "}
                                </div>
                                <div className={s.dropdownContent}>
                                    <Link to="/personal">Account</Link>
                                    <Link to="" onClick={handleLogout}>
                                        Logout
                                    </Link>
                                </div>
                            </div>
                        ) : (
                            <>

                            </>
                        )}
                    </div>
                </div>
            </div>

            <div className={s.header_container_mobile}>
                <img src={burgerMenu} alt=""
                     className={s.burger_menu_btn}
                     onClick={() => {setIsMenuOpen(true)}}
                />
                <Sidebar pageWrapId={'page-wrap'}
                         outerContainerId={'outer-container'}
                         isMenuOpen={isMenuOpen}
                         setIsMenuOpen={setIsMenuOpen}
                />
                <Link to="/">
                    <div className={s.mob_logo_wrapper}>
                        {localStorage.getItem('lang') === 'en'
                            ? <img src={logo} alt="logo" className={s.logo_mob}/>
                            : <img src={logoTrWhite} alt="logo" className={s.logo_mob}/>
                        }
                    </div>
                </Link>
                <Link to="/login">
                    <img src={LoginBtnMobile} className={s.login_btn_mob} alt={'log in'}/>
                </Link>
            </div>
        </div>;
    }

    return (
        <div className={s.headerWrapper} id={'1'}>
            <div className={s.headerContainer}>
                <div className={s.left}>
                    <Link to="/">
                        { getLogo() }
                    </Link>
                    <Link to="/login">
                        <div className={s.loginButtonBlock}>
                            <img src={login} alt="login"/>
                            <span className={s.login}>{t('main.login')}</span>
                        </div>
                    </Link>
                    <div>
                        <button className={s.signUpButton}
                                onClick={() => {
                                    setPopupIsVisible(true)
                                }}
                        >{t('main.sign_up')}
                        </button>
                    </div>
                    <div className={s.lng_block}>
                        <div className={localStorage.getItem('lang') === 'en' ? s.active_lang : s.lng}
                             onClick={handleChangeLanguage}>
                            EN
                        </div>
                        <div className={localStorage.getItem('lang') === 'ru' ? s.active_lang : s.lng}
                             onClick={handleChangeLanguage}>
                            RU
                        </div>
                        <div className={localStorage.getItem('lang') === 'tr' ? s.active_lang : s.lng}
                             onClick={handleChangeLanguage}>
                            TR
                        </div>

                    </div>
                </div>
                {/*<div className={s.right}>*/}
                {/*    /!*<button onClick={() => {handleChangeLanguage('tu')}}>*!/*/}
                {/*    /!*    change lng*!/*/}
                {/*    /!*</button>*!/*/}
                {/*    <ScrollLink*/}
                {/*        className={getStylesForRussian()}*/}
                {/*        activeClass={s.active}*/}
                {/*        to="2"*/}
                {/*        spy={true}*/}
                {/*        smooth={true}*/}
                {/*        offset={0}*/}
                {/*        duration={500}*/}
                {/*    >*/}
                {/*        <div className={s.link}>{t('main.banking')}</div>*/}
                {/*    </ScrollLink>*/}

                {/*    <ScrollLink*/}
                {/*        className={getStylesForRussian()}*/}
                {/*        activeClass={s.active}*/}
                {/*        to="3"*/}
                {/*        spy={true}*/}
                {/*        smooth={true}*/}
                {/*        offset={0}*/}
                {/*        duration={500}*/}
                {/*    >*/}
                {/*        <div className={s.link}>{t('main.savings_account')}</div>*/}
                {/*    </ScrollLink>*/}

                {/*    <ScrollLink*/}
                {/*        className={getStylesForRussian()}*/}
                {/*        activeClass={s.active}*/}
                {/*        to="4"*/}
                {/*        spy={true}*/}
                {/*        smooth={true}*/}
                {/*        offset={0}*/}
                {/*        duration={500}*/}
                {/*    >*/}
                {/*        <div className={s.link}>{t('main.debit_cards')}</div>*/}
                {/*    </ScrollLink>*/}

                {/*    <ScrollLink*/}
                {/*        className={getStylesForRussian()}*/}
                {/*        activeClass={s.active}*/}
                {/*        to="5"*/}
                {/*        spy={true}*/}
                {/*        smooth={true}*/}
                {/*        offset={0}*/}
                {/*        duration={500}*/}
                {/*    >*/}
                {/*        <div className={s.link}>{t('main.e_money')}</div>*/}
                {/*    </ScrollLink>*/}

                {/*    <Link to="/contact-us" >*/}
                {/*        <div className={s.link}>*/}
                {/*                {t('main.contact_us')}*/}
                {/*        </div>*/}
                {/*    </Link>*/}

                {/*    /!*<div className={s.dropdown}>*!/*/}
                {/*    /!*    <div className={`${s.dropBtn}`}>{t('main.about_us')}</div>*!/*/}
                {/*    /!*    <div className={s.dropdownContent}>*!/*/}
                {/*    /!*        <Link to="/contact-us" activeClassName={s.active}>*!/*/}
                {/*    /!*            {t('main.contact_us')}*!/*/}
                {/*    /!*        </Link>*!/*/}
                {/*    /!*        /!*<Link to="/api-guide" activeClassName={s.active}>*!/*!/*/}
                {/*    /!*        /!*    Api guide*!/*!/*/}
                {/*    /!*        /!*</Link>*!/*!/*/}
                {/*    /!*        <Link to="/investor-relations" activeClassName={s.active}>*!/*/}
                {/*    /!*            {t('main.investor_relations')}*!/*/}
                {/*    /!*        </Link>*!/*/}
                {/*    /!*    </div>*!/*/}
                {/*    /!*</div>*!/*/}

                {/*    <MyPopup visible={popupIsVisible} setVisible={setPopupIsVisible}/>*/}

                {/*    {Helper.checkAuth(auth) ? (*/}
                {/*        <div className={s.dropdown}>*/}
                {/*            <div className={`${s.loginButtonBlock} ${s.dropBtn}`}>*/}
                {/*                {auth?.user?.name || "Account"}{" "}*/}
                {/*            </div>*/}
                {/*            <div className={s.dropdownContent}>*/}
                {/*                <Link to="/personal">Account</Link>*/}
                {/*                <Link to="" onClick={handleLogout}>*/}
                {/*                    Logout*/}
                {/*                </Link>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    ) : (*/}
                {/*        <>*/}

                {/*        </>*/}
                {/*    )}*/}
                {/*</div>*/}
            </div>
            <div className={s.header_container_mobile}>
                <img src={burgerMenu} alt=""
                     className={s.burger_menu_btn}
                     onClick={() => {setIsMenuOpen(true)}}
                />
                <Sidebar pageWrapId={'page-wrap'}
                         outerContainerId={'outer-container'}
                         isMenuOpen={isMenuOpen}
                         setIsMenuOpen={setIsMenuOpen}
                />
                <Link to="/">
                    <div className={s.mob_logo_wrapper}>
                        {getLogoMob()}
                    </div>
                </Link>
                <Link to="/login">
                    <img src={LoginBtnMobile} className={s.login_btn_mob} alt={'log in'}/>
                </Link>
            </div>
        </div>
    );
};

export default withTranslation()(Header)


