import React, {useState} from "react";
import s from "./Home.module.css";
import mainBg from "../../assets/img/home/mainBG.png";
import savingImg from "../../assets/img/home/saving_account.png";
import savingImgMob from "../../assets/img/saving_account_mobile_img.png";
import Calculate from "../calculate/Calculate";
import DebitCards from "../debitCards/DebitCards";
import MyPopup from "../../utils/MyPopup/MyPopup";
import {withTranslation} from "react-i18next";
import {t} from "i18next";
import classNames from "classnames";
import '../../assets/css/main.css'

const Home = () => {

    const [popupIsVisible, setPopupIsVisible] = useState(false)

    return (
        <div className={s.homeWrapper}>
            <div className={s.homeContent}>
                {/*Main Screen start*/}
                <MyPopup visible={popupIsVisible} setVisible={setPopupIsVisible}/>

                <div className={s.main}>
                    <img src={mainBg} alt="" className={s.main_bg}/>
                    <div className={s.blur}>
                        <div className={classNames(s.name, 'ttr-title')}>{t('main.turkey')} <br/>
                            {t('main.international')}
                        </div>
                    </div>
                    <div className={classNames(s.name2, 'ttr-title')}>{t('main.investment')} <br/>
                        {t('main.bank')}
                    </div>
                    <div className={s.desc_block}>
                        <div className={s.desc_title}>
                            {t('main.desc_title')}
                        </div>
                        <div className={s.desc_subtitle}>
                            {t('main.desc_subtitle')}
                        </div>
                        <button className={s.my_btn} onClick={() => {setPopupIsVisible(true)}}>{t('main.open_account')}</button>
                    </div>
                </div>

                {/*Main screen end*/}

                {/*Main screen mobile start*/}

                <div className={s.main_screen_mobile}>
                    <div className={s.name_mobile_block}>
                        <div className={s.name_mobile}>{t('main.turkey')}</div>
                        <div className={s.name_mobile}>{t('main.international')}</div>
                        <div className={s.name_mobile}>{t('main.investment')}</div>
                        <div className={s.name_mobile}>{t('main.bank')}</div>
                    </div>
                    <div className={s.mobile_title}>
                        {t('main.desc_title')}
                    </div>
                    <div className={s.mobile_subtitle}>
                        {t('main.desc_subtitle')}
                    </div>
                    <button className={s.my_btn_mobile} onClick={() => {setPopupIsVisible(true)}}>{t('main.open_account')}</button>
                </div>

                {/*Main screen mobile end*/}

                {/*Banking block start*/}

                <div className={s.banking} id={"2"}>
                    <div className={s.banking_content}>
                        <div className={classNames(s.banking_left, 'ttr-title')}>{t('main.banking')}</div>
                        <div className={s.banking_right}>
                            <div className={s.dropdown_menu1}>
                                <div className={s.dropdown_menu_num}>01.</div>
                                <div className={s.dropdown_menu_title}>{t('main.banking_service')}</div>
                            </div>
                            <div className={s.dropdown_content1}>
                                {t('main.banking_service_content')}
                            </div>
                            <div className={s.dropdown_menu2}>
                                <div className={s.dropdown_menu_num}>02.</div>
                                <div className={s.dropdown_menu_title}>
                                    {t('main.universal_personal_accounts')}
                                </div>
                            </div>
                            <div className={s.dropdown_content2}>
                                {t('main.universal_personal_accounts_desc')}
                            </div>
                            <div className={s.dropdown_menu3}>
                                <div className={s.dropdown_menu_num}>03.</div>
                                <div className={s.dropdown_menu_title}>
                                    {t('main.acquiring_services')}
                                </div>
                            </div>
                            <div className={s.dropdown_content3}>
                                {t('main.acquiring_services_desc_p1')} <br/> <br/>
                                {t('main.acquiring_services_desc_p2')} <br/> <br/>
                                {t('main.acquiring_services_desc_p3')} <br/>
                                {t('main.acquiring_services_desc_p4')} <br/>
                                {t('main.acquiring_services_desc_p5')} <br/>
                                {t('main.acquiring_services_desc_p6')}
                            </div>
                            <div className={s.dropdown_menu4}>
                                <div className={s.dropdown_menu_num}>04.</div>
                                <div className={s.dropdown_menu_title}>
                                    {t('main.fueling_your_e_commerce')}
                                </div>
                            </div>
                            <div className={s.dropdown_content4}>
                                {t('main.fueling_your_e_commerce_desc')}
                            </div>
                        </div>
                    </div>
                </div>

                {/*Banking block end*/}

                {/*saving account block start*/}
                <div className={s.saving_account_wrapper} id={"3"}>
                    <div className={s.img_block}>
                        <img src={savingImg} alt=""/>
                    </div>
                    <div className={s.img_block_mob}>
                        <img src={savingImgMob} alt=""/>
                    </div>
                    <div className={s.saving_text_block}>
                        <div className={classNames(s.saving_title, 'ttr-title')}>
                            {t('main.saving')}  {t('main.account')}
                        </div>
                        <div className={s.saving_subtitle}>{t('main.deposit_with')} {t('main.investment_bank')}</div>
                        <div className={s.saving_desc}>
                            {t('main.saving_desc')}
                        </div>
                    </div>
                </div>
                {/*saving account block end*/}

                {/*calculate Block start*/}
                <Calculate/>
                {/*calculate Block end*/}

                {/*debit cards block start*/}
                <DebitCards/>
                {/*debit cards block end*/}

                {/*e-money block start*/}
                <div className={s.e_money} id={"5"}>
                    <div className={s.banking_content}>
                        <div className={classNames(s.banking_left, 'ttr-title')}>{t('main.e_money')}</div>
                        <div className={s.banking_right}>
                            <div className={s.dropdown_menu1}>
                                <div className={s.dropdown_menu_num}>01.</div>
                                <div className={s.dropdown_menu_title}>
                                    {t('main.e_money_title_1')}
                                </div>
                            </div>
                            <div className={s.dropdown_content1}>
                                {t('main.e_money_desc_1')}
                            </div>
                            <div className={s.dropdown_menu2}>
                                <div className={s.dropdown_menu_num}>02.</div>
                                <div className={s.dropdown_menu_title}>
                                    {t('main.e_money_title_2')}
                                </div>
                            </div>
                            <div className={s.dropdown_content2}>
                                {t('main.e_money_desc_2')}
                            </div>
                        </div>
                    </div>
                </div>
                {/*e-money block end*/}
            </div>
        </div>
    );
};

export default withTranslation()(Home)
