import React, {useState} from 'react';
import {t} from "i18next";
import MyPopupSuccess from "../../../../utils/MyPopupSuccess/MyPopupSuccess";
import Account from "../../Account";
import s from "./AccountOpening.module.css";
import MyPopupError from "../../../../utils/MyPopupError/MyPopupError";
import download from "../../../../assets/img/download.png";
import pdf_img from "../../../../assets/img/pdf.png";
import {API} from "../../../../utils/api/api";

const AccountOpening = () => {

    const [popupVisible, setPopupVisible] = useState(false)

    const rootClasses = [s.myModal]

    const [popupSuccessVisible, setPopupSuccessVisible] = useState(false)
    const [popupErrorVisible, setPopupErrorVisible] = useState(false)
    const [selectedFile, setSelectedFile] = useState(false);
    const [sendUserData, setSendUserData] = useState({
        name: null,
    })

    const sendMessage = async () => {

        await API.sendUserData(sendUserData)
            .then((res) => {
                console.log(res)
                setPopupSuccessVisible(true)
            }).catch(() => {
                setPopupErrorVisible(true)
            })
    }

    const changeHandler = async (event) => {
        let reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = function () {
            setSelectedFile(true)
            setSendUserData({...sendUserData, file_pdf: reader.result})
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    };

    const getPdfLinkCorporate = () => {
        if (localStorage.getItem('lang') === 'en') {
            return "/Account_Opening_Form_For_Individuals.pdf"
        } else if (localStorage.getItem('lang') === 'tr') {
            return "/Kişisel_Hesap_Açma_Formu.pdf"
        } else {
            return "/Заявление_для_открытия_личного_счета.pdf"
        }
    }

    const getPdfLinkPersonal = () => {
        if (localStorage.getItem('lang') === 'en') {
            return "/Account_Opening_Form_For_Corporate.pdf"
        } else if (localStorage.getItem('lang') === 'tr') {
            return "/Kurumsal_Hesap_Açm_Formu.pdf"
        } else {
            return "/Заявление_для_открытия_корпоративного_счета.pdf"
        }
    }

    return (
        <Account
            activeTab="account-opening"
            title={t("popup.title_opening")}
        >
            <MyPopupSuccess visible={popupVisible} setVisible={setPopupVisible}/>
            <div className={rootClasses.join(' ')}>
                <div className={s.myModalContent} onClick={(e) => e.stopPropagation()}>
                    <MyPopupSuccess visible={popupSuccessVisible} setVisible={setPopupSuccessVisible}/>
                    <MyPopupError visible={popupErrorVisible} setVisible={setPopupErrorVisible}/>
                    {/*<div className={s.close_popup}>*/}
                    {/*    <img src={closePopup} alt="close" onClick={() => setVisible(false)}/>*/}
                    {/*</div>*/}

                    {/*<div className={s.popup_title}>*/}
                    {/*    {t('popup.title_opening')}*/}
                    {/*</div>*/}

                    <div className={s.step_one_block}>
                        <div className={s.step_title}>01. {t('popup.download')}</div>
                        <div className={s.download_pdf}>
                            <img src={pdf_img} alt=""/>

                                <a href={getPdfLinkCorporate()} download>
                                    <span className={s.pdf_name}>{t('popup.personal')}</span>
                                    <img className={s.download_img} src={download} alt=""/>
                                </a>

                        </div>

                        <div className={s.download_pdf}>
                            <img src={pdf_img} alt=""/>
                                <a href={getPdfLinkPersonal()} download>
                                    <span className={s.pdf_name}>{t('popup.corporate')}</span>
                                    <img className={s.download_img} src={download} alt=""/>
                                </a>
                        </div>
                    </div>

                    <div className={s.step_one_block}>
                        <div className={s.step_title}>02. {t('popup.upload')}</div>
                        <div className={s.download_pdf}>
                            <button className={s.my_btn}>
                                {t('popup.add')}
                                <input type="file"
                                       name="file"
                                       placeholder={'ADD'}
                                       onChange={changeHandler}
                                       className={s.upload_pdf}
                                />
                            </button>
                            {!!selectedFile && <div className={s.done}>{t('popup.success')}</div>}
                        </div>

                    </div>
                    {/*<div className={s.flex}>*/}
                    {/*    <div className={s.popup_subtitle}>*/}
                    {/*        {t('popup.subtitle')}*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<div className={s.form}>*/}
                    {/*    <div className={s.input_block}>*/}
                    {/*        <span>{t('popup.name')}</span>*/}
                    {/*        <input type="text"*/}
                    {/*               value={sendUserData.name}*/}
                    {/*               onChange={(e) => {*/}
                    {/*                   setSendUserData({...sendUserData, name: e.currentTarget.value})*/}
                    {/*               }}*/}
                    {/*        />*/}
                    {/*    </div>*/}
                    {/*    <div className={s.input_block}>*/}
                    {/*        <span>{t('popup.phone')}</span>*/}
                    {/*        <input type="text"*/}
                    {/*               value={sendUserData.phone}*/}
                    {/*               onChange={(e) => {*/}
                    {/*                   setSendUserData({...sendUserData, phone: e.currentTarget.value})*/}
                    {/*               }}*/}
                    {/*        />*/}
                    {/*    </div>*/}
                    {/*    <div className={s.input_block}>*/}
                    {/*        <span>{t('popup.email')}</span>*/}
                    {/*        <input type="text"*/}
                    {/*               value={sendUserData.email}*/}
                    {/*               onChange={(e) => {*/}
                    {/*                   setSendUserData({...sendUserData, email: e.currentTarget.value})*/}
                    {/*               }}*/}
                    {/*        />*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    <div className={s.flex}>
                        <button className={s.my_btn} onClick={sendMessage}>{t('popup.send')}</button>
                    </div>

                </div>
            </div>

        </Account>
    );
};

export default AccountOpening;
