import React, {useState} from 'react';
import s from './DebitCards.module.css'
import visa from '../../assets/img/home/visa.png'
import union from '../../assets/img/home/unionpay.png'
import myGif from '../../assets/gif/card.gif'
import MyPopup from "../../utils/MyPopup/MyPopup";
import {t} from "i18next";
import classNames from "classnames";


const DebitCards = () => {
    const [popupIsVisible, setPopupIsVisible] = useState(false)

    return (
        <div className={s.wrapper} id={'4'}>
            <MyPopup visible={popupIsVisible} setVisible={setPopupIsVisible}/>
            <div className={s.bg_img}>
                <div className={s.debit_content}>
                    <div className={s.left_side}>
                        <div className={classNames(s.title, 'ttr-title')}>{t('main.debit_cards_block_title')}</div>
                        <div className={s.subtitle}>{t('main.debit_cards_block_subtitle')}</div>
                        <div className={s.text} style={{marginBottom: '13px'}}>
                            {t('main.debit_cards_block_desc')}
                        </div>
                        <div className={s.text} style={{marginBottom: '31px'}}>
                            {t('main.debit_cards_block_desc2')}
                        </div>
                        <div>
                            <button className={s.my_btn} onClick={() => {
                                setPopupIsVisible(true)
                            }}>
                                {t('main.sign_up')}
                            </button>
                        </div>
                    </div>
                    <div className={s.right_side}>
                        <div>
                            <img className={s.union} src={union} alt=""/>
                            <img className={s.visa} src={visa} alt=""/>
                        </div>
                        <div className={s.gif}>
                            <img src={myGif} alt="" className={s.my_gif}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DebitCards;
