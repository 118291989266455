import s from "./App.module.css";
import Header from "./Components/header/Header";
import Footer from "./Components/footer/Footer";
import { Switch, Route, Redirect } from "react-router-dom";
import Home from "./Components/home/Home";
import ApiGuide from "./Components/apiGuide/ApiGuide";
import ContactUs from "./Components/contactUs/ContactUs";
import InvestorRelations from "./Components/investorRelations/InvestorRelations";
import { Link } from "react-scroll";
import onTop from "./assets/img/onTop.png";
import Login from "./Components/login/Login";
import AccountDetails from "./Components/account/accountPages/accountDetails/AccountDetails";
import Exchange from "./Components/account/accountPages/exchange/Exchange";
import Swift from "./Components/account/accountPages/swift/Swift";
import Statement from "./Components/account/accountPages/statement/Statement";
import InternalTransfer from "./Components/account/accountPages/Internal/InternalTransfer";
import { useSelector } from "react-redux";
import { NotificationContainer } from "react-notifications";
import PopupAlert from "./Components/popup/PopupAlert";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-notifications/lib/notifications.css";
import "reactjs-popup/dist/index.css";
import { Helper } from "./utils/helper";
import React from "react";
import AccountOpening from "./Components/account/accountPages/accountOpening/AccountOpening";
import '../src/assets/css/main.css'
import i18n from "./i18n";

function App() {
  const state = useSelector((state) => state);
  const { AuthReducer: auth, UserReducer: user } = state;

  if (!localStorage.getItem("lang")) {
     localStorage.setItem("lang","en")
  }

    i18n.changeLanguage(localStorage.getItem("lang"));

  //
  // let lng =
  //
  // useEffect(() => {
  //   lng = localStorage.getItem("lang")
  //   console.log("1")
  // })

  return (
    <div className={s.App}>
      <div className={localStorage.getItem("lang")}>
        <div className="ttr-body">
            <Header auth={auth} />

            <div className={s.mainContent}>
                <Switch>
                    {/*<Route exact path="/" component={Home} />*/}

                    <PublicRoute auth={auth} exact path="/" component={Login} />

                    <PrivateRoute
                        auth={auth}
                        exact
                        path={"/personal"}
                        component={AccountDetails}
                    />
                    <PrivateRoute
                        auth={auth}
                        path={"/personal/statement/"}
                        component={Statement}
                    />
                    <PrivateRoute
                        auth={auth}
                        path={"/personal/swift-transfer"}
                        component={Swift}
                    />
                    <PrivateRoute
                        auth={auth}
                        path={"/personal/exchange-transfer"}
                        component={Exchange}
                    />
                    <PrivateRoute
                        auth={auth}
                        path={"/personal/internal-transfer"}
                        component={InternalTransfer}
                    />
                    <PrivateRoute
                        auth={auth}
                        path={"/personal/account-opening"}
                        component={AccountOpening}
                    />

                    {/*<Route exact path="/api-guide" component={ApiGuide} />*/}
                    {/*<Route exact path="/contact-us" component={ContactUs} />*/}
                    {/*<Route*/}
                    {/*    exact*/}
                    {/*    path="/investor-relations"*/}
                    {/*    component={InvestorRelations}*/}
                    {/*/>*/}
                </Switch>
            </div>
            <Footer />
            <div className={s.arrow_up}>
                <Link
                    activeClass={s.active}
                    to="1"
                    spy={true}
                    smooth={true}
                    offset={0}
                    duration={500}
                >
                    <img src={onTop} alt="" />
                </Link>
            </div>
            <NotificationContainer />
            <PopupAlert />
        </div>
      </div>
    </div>
  );
}

function PublicRoute({ auth, path, component }) {
  return !Helper.checkAuth(auth) ? (
    <Route path={path} exact component={component} />
  ) : (
    <Redirect to="/personal" />
  );
}

function PrivateRoute({ auth, exact = false, path, component }) {
  return Helper.checkAuth(auth) ? (
    <Route path={path} exact={exact} component={component} />
  ) : (
    <Redirect to="/login" />
  );
}

export default App;
