import React from "react";
import { Button, Stack } from "react-bootstrap";

function Message({ data }) {
  return (
    <div className="p-5 d-flex justify-content-center align-item-center text-center">
      <div>
        {data?.title && data?.title !== "" && <h1>{data?.title}</h1>}
        {data?.message && data?.message !== "" && (
          <p className="pt-3">{data?.message}</p>
        )}
        <Stack
          direction="horizontal"
          gap={3}
          className="justify-content-center align-item-center pt-3"
        >
          <Button
            className="px-4 py-2"
            onClick={() => data.onClose()}
            variant={data?.type === "error" ? "danger" : data?.type}
          >
            Ok
          </Button>
        </Stack>
      </div>
    </div>
  );
}

export default Message;
