import React, { useState } from "react";
import s from "./ApiGuide.module.css";
import mainBg from "../../assets/img/apiGuide/apiBG.png";
import MyPopupSuccess from "../../utils/MyPopupSuccess/MyPopupSuccess";
import MyPopupError from "../../utils/MyPopupError/MyPopupError";
import {t} from "i18next";
import {withTranslation} from "react-i18next";
import classNames from "classnames";

const ApiGuide = () => {
  const [data, setData] = useState({
    firstName: "",
    phone: "",
    message: "",
  });

  const [popupVisible, setPopupVisible] = useState(false)
  const [popupErrorVisible, setPopupErrorVisible] = useState(false)

  const dataForSend = {
    project: "turkey",
    subject: "Hello!",
    data: data
  }

  const codeExample = `headers: {
            Content-Type: application/json 
            Authorization: Bearer TOKEN (receive after authorization)
        }`;

  const sendMessage = async () => {
    const headers = {
      "Content-Type": "application/json",
      // Authorization: `Bearer ${token}`,
    }

    return fetch("https://assembly.alexandriabancorp.ltd/api/send_form/from", {
      method: "POST",
      headers,
      body: JSON.stringify(dataForSend),
    }).then((response) => {
      setPopupVisible(true)
      console.log(response)
    }).catch((error) => {
      console.log(error)
      setPopupErrorVisible(true)
    })
  }

  return (
    <div>

      <MyPopupSuccess visible={popupVisible} setVisible={setPopupVisible}/>
      <MyPopupError visible={popupErrorVisible} setVisible={setPopupErrorVisible}/>

      {/*<div>*/}
      {/*  <img src={mainBg} alt="" className={s.main_bg} />*/}
      {/*  <div className={s.blur}></div>*/}
      {/*  <div className={s.main_text}>*/}
      {/*    {t("api_guide.api")}*/}
      {/*  </div>*/}
      {/*  <div className={s.main_text2}>*/}
      {/*    {t("api_guide.guide")}*/}
      {/*  </div>*/}
      {/*</div>*/}

      <div className={s.main}>
        <img src={mainBg} alt="" className={s.main_bg}/>

        { localStorage.getItem('lang') === 'tr'
            ? <div className={s.blur}>
              <div className={classNames(s.main_text_tur, 'ttr-title')}>
                {t("api_guide.api")}
                <div className={classNames(s.main_text2_tur, 'ttr-title')}>
                  {t("api_guide.guide")}
                </div>
              </div>
            </div>
            : <div className={s.blur}>
              <div className={classNames(s.main_text, 'ttr-title')}>
                {t("api_guide.api")}
                <div className={classNames(s.main_text2, 'ttr-title')}>
                  {t("api_guide.guide")}
                </div>
              </div>
            </div>
        }
      </div>

      <div className={s.main_mobile}>
        <div className={classNames(s.main_text, 'ttr-title')}>
          {t("api_guide.api")}  {t("api_guide.guide")}
        </div>
      </div>

      <div className={s.api_guide_contact_block}>
        <div className={s.left}>
          <div className={classNames(s.title, 'ttr-title')}>
            {t("api_guide.start")} <br />
            {t("api_guide.it_now")}
          </div>
        </div>
        <form
          className={s.right}
          onSubmit={(e) => {
            e.preventDefault();
            sendMessage();
          }}
        >
          <div className={s.form_text}>
            {t("api_guide.difficulties")}
          </div>
          <div className={s.form_wrapper}>
            <div className={s.inputs_block}>
              <div className={s.input_name}>{t("contact.name")}</div>
              <input
                required
                className={s.my_input}
                type="text"
                style={{ marginBottom: "23px" }}
                value={data.firstName}
                onChange={(e) => setData({ ...data, firstName: e.target.value })}
              />
              <div className={s.input_name}>{t("contact.phone")}</div>
              <input
                required
                className={s.my_input}
                type="text"
                value={data.phone}
                onChange={(e) => setData({ ...data, phone: e.target.value })}
              />
            </div>
            <div>
              <div className={s.input_name}>
                {t("contact.message")}
              </div>
              <textarea required className={s.my_textarea} value={data.message}
                        onChange={(e) => setData({ ...data, message: e.target.value })}/>
            </div>
          </div>
          <button type="submit" className={s.my_btn}>
            {t("popup.send")}
          </button>
        </form>
      </div>
      <div className={s.api_guide_content_block}>
        <div className={s.content_title}>
          {t("api_guide.json")}
        </div>
        <div className={s.subtitle}>
          {t("api_guide.headers_block")}
        </div>

        <div className={s.example}>{t("api_guide.example")}</div>

        <div className={s.code_block}>
          <pre>
            <div
              dangerouslySetInnerHTML={{ __html: codeExample }}
            ></div>
          </pre>
        </div>

        <div className={s.subtitle}>{t("api_guide.available_endpoints")}</div>

        <div className={s.subtitle}>{t("api_guide.log_in")}</div>

        <div className={s.example}>{t("api_guide.example")}</div>

        <div className={s.code_block}>
          <p>
            Method: POST <br />
            EndPoint:{" "}
            <span style={{ wordBreak: "break-all" }}>
              https://api1.maeatrading.site/api/user/login
            </span>{" "}
            <br />
            <br />
            Params: <br />
            <br />
            login - string - your login <br />
            password - string - your password <br />
          </p>
        </div>

        <div className={s.example}>{t("api_guide.response")}</div>

        <div className={s.code_block}>
          <pre>
            {`Response status is always HTTP_OK (200)

in case of success:
{
    “status” : 200,
    “result” : {
    “token” : “TOKEN”
    }
}

in case of error:
{
    “status” : 418,
    “errors” : [
    ..// Errors (example: 'parameter is missing: validity')
    ]
}

or

{
    “status” : 500,
    “errors” : “Server Error - {message}”
}`}
          </pre>
        </div>

        <div className={s.subtitle}>{t("api_guide.tool_signature_request")}</div>

        <div className={s.example}>{t("api_guide.example")}</div>

        <div className={s.code_block}>
          <p>
            Method: POST <br />
            EndPoint: /api/transaction/creates/payments <br />
            <br />
            Params: <br />
            <br />
            payer_id - string - your id in our system (check with the admin){" "}
            <br />
            owner - string - Payer's name (From the card) <br />
            card_number - string - Card number (From the card) <br />
            cvv - integer - number on the back of the card (From the card){" "}
            <br />
            validity - string - card expiry date. Example: “01/22” (From card){" "}
            <br />
            amount - float/integer - write-off amount <br />
            currency - string - currency . Example: "USD", always in upper case.{" "}
            <br />
          </p>
        </div>

        <div className={s.example}>{t("api_guide.response")}</div>

        <div className={s.code_block}>
          <pre>
            {`Response status is always HTTP_OK (200)

in case of success:
{
    “status” : 200,
    “result” : {
    “transaction” : “transaction number” ,(99681628714189)
    “status”: 1|2|3|5,
    “redirect_url”: “http://exemple.com/…..” (3DS, option)
    }
}

*redirect_url - url to redirect the user to pass 3DS.
after passing the 3DS, the user will be redirected to the authentication completion page.
Possible statuses:
STATUS_IN_PROCESS = 1;
STATUS_APPROVED = 2;
STATUS_DENIED = 3;
STATUS_WAITING_ CONFIRMATION = 5;
“status”: “STATUS_IN_PROCESS” - Transaction in progress, check status after a while
“status”: “STATUS_APPROVED” - Transaction completed
“status”: “STATUS_DENIED” - The transaction was NOT completed due to reasons beyond our control
“status”: “STATUS_WAITING_ CONFIRMATION” - waiting for confirmation from the user

in case of error:
{
    “status” : 418,
    “errors” : [
    ..// Errors (example: 'parameter is missing: validity')
    ]
}

or

{
    “status” : 500,
    “errors” : “Server Error - {message}”
}`}
          </pre>
        </div>

        <div className={s.subtitle}>{t("api_guide.transaction_status_request")}</div>

        <div className={s.example}>{t("api_guide.example")}</div>

        <div className={s.code_block}>
          <p>
            Method: GET
            <br />
            EndPoint: api/transaction/transactions/{`{id}`}/status <br />
            <br />
            Params: <br />
            <br />
            id - string - transaction number (you receive in the response with a
            successful request to withdraw funds)
            <br />
          </p>
        </div>

        <div className={s.example}>{t("api_guide.example")}</div>

        <div className={s.code_block}>
          <pre>
            {`Response status is always HTTP_OK (200)

in case of success:
{
    “status” : 200,
    “result” : {
    “status” : (int) transaction status
    }
}

Possible statuses:
STATUS_IN_PROCESS = 1;
STATUS_APPROVED = 2;
STATUS_DENIED = 3;
STATUS_REFUND = 4;
STATUS_WAITING_ CONFIRMATION = 5;

in case of error:
{
    “status” : 418,
    “errors” : [
    ..// Errors (example: 'parameter is missing: validity')
    ]
}

or

{
    “status” : 500,
    “errors” : “Server Error”
}`}
          </pre>
        </div>

        <div className={s.subtitle}>{t("api_guide.example")}</div>

        <div className={s.example}>{t("api_guide.refund")}</div>

        <div className={s.code_block}>
          <p className="example text">
            Method: POST
            <br />
            EndPoint: /api/transaction/refunds <br />
            <br />
            Params: <br />
            <br />
            payer_id - string - your id in our system (check with the admin)
            <br />
            transaction_id - string - transaction number
            <br />
            <br />
          </p>
        </div>

        <div className={s.example}>{t("api_guide.response")}</div>

        <div className={s.code_block}>
          <pre>
            {`Response status is always HTTP_OK (200)

in case of success:
{
    “status” : 200,
    “result” : "OK"
}

in case of error:
{
    “status” : 418,
    “errors” : “Query params is not valid”
}

or

{
    “status” : 403,
    “errors” : “Query params is not valid”
}

or

{
    “status” : 500,
    “errors” : “Sever Error - {message}”
}`}
          </pre>
        </div>

        <div className={s.subtitle}>{t("api_guide.transaction_info")}</div>

        <div className={s.example}>{t("api_guide.example")}</div>

        <div className={s.code_block}>
          <p className="example text">
            Method: GET
            <br />
            EndPoint: https://api1.maeatrading.site/api/
            transaction/transactions/{`{transaction number}`}/info <br />
            <br />
          </p>
        </div>

        <div className={s.example}>{t("api_guide.response")}</div>

        <div className={s.code_block}>
          <pre>
            {`Response status is always HTTP_OK (200)

in case of success:
{
    “status” : 200,
    “result” : {
    "transaction_number": "94621337271640",
    "transaction_status": 3,
    "transaction_amount": 0.01,
    "net_amount": 0.01,
    "transaction_date_time": "2021-11-22 08:40:40 UTC",
    "is_live_transaction": true,
    "card_owner_name": "Test Test",
    "card_number": "6690"
}

in case of error:
{
    “status” : 418,
    “errors” : “Transaction is not found”
}`}
          </pre>
        </div>

        <div className={s.subtitle}>{t("api_guide.transactions_list")}</div>

        <div className={s.example}>{t("api_guide.example")}</div>

        <div className={s.code_block}>
          <p className="example text">
            Method: POST
            <br />
            EndPoint: https://api1.maeatrading.site/ api/transaction/all/
            transactions/info
            <br />
            <br />
            <br />
            Params: <br />
            <br />
            payer_id - string - your id in our system (check with the admin){" "}
            <br />
            isLiveTransaction - string - optional (flag test transaction or not){" "}
            <br />
            startDate - string - optional (passing date in timestamp format){" "}
            <br />
            endDate - string - optional (passing date in timestamp format){" "}
            <br />
          </p>
        </div>

        <div className={s.example}>{t("api_guide.response")}</div>

        <div className={s.code_block}>
          <pre>
            {`Response status is always HTTP_OK (200)

in case of success:
{
    “status” : 200,
    “result” :
    [
      {
        "transaction_number": "94621337271640",
        "transaction_status": 3,
        "transaction_amount": 0.01,
        "net_amount": 0.01,
        "transaction_date_time": "2021-11-22 08:40:40 UTC",
        "is_live_transaction": true,
        "card_owner_name": "Test Test",
        "card_number": "6690"
      }
      {...}
      {...}
      {...}
    ]
}

in case of error:
{
    “status” : 418,
    “errors” : “Missing params”
}
`}
          </pre>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(ApiGuide)
