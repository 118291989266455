import { createSlice } from "@reduxjs/toolkit";

let initialState = {
  user: null,
  token: "",
  isAuth: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthUser(state, action) {
      state.user = action.payload;
      state.isAuth = true;
    },
    setToken(state, action) {
      state.token = action.payload;
    },
    logout(state, action) {
      state.user = null;
      state.token = "";
      state.isAuth = false;
      localStorage.removeItem("token")
    },
  },
});

export default authSlice.reducer;
