import React, {useState} from 'react';
import s from './ContactUs.module.css'
import mainBg from "../../assets/img/contact/contactBg.png";
import mail from "../../assets/img/contact/Mail.png";
import phone from "../../assets/img/contact/Phone.png";
import map from "../../assets/img/contact/Map.png";
import linkedin from "../../assets/img/contact/LinkedIN.png";
import facebook from "../../assets/img/contact/Facebook.png";
import whatsapp from "../../assets/img/contact/WhatsApp.png";
import signal from "../../assets/img/contact/Signal.png";
import telegram from "../../assets/img/contact/Telegram.png";
import MyPopupSuccess from "../../utils/MyPopupSuccess/MyPopupSuccess";
import {t} from "i18next";
import {withTranslation} from "react-i18next";
import MyPopupError from "../../utils/MyPopupError/MyPopupError";
import classNames from "classnames";

const ContactUs = () => {

    const [data, setData] = useState({
        firstName: '',
        message: '',
        phone: '',
        email: ''
    })

    const [popupVisible, setPopupVisible] = useState(false)
    const [popupErrorVisible, setPopupErrorVisible] = useState(false)

    const dataForSend = {
        project: "turkey",
        subject: "Hello!",
        data: data
    }

    const sendMessage = () => {

        const headers = {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${token}`,
        }

        return fetch("https://assembly.alexandriabancorp.ltd/api/send_form/from", {
            method: "POST",
            headers,
            body: JSON.stringify(dataForSend),
        }).then((response) => {
            setPopupVisible(true)
            console.log(response)
        }).catch((error) => {
            console.log(error)
            setPopupErrorVisible(true)
        })

    }

    const getClassNameText = () => {
        if (localStorage.getItem('lang') === 'en') {
            return s.main_text
        } else if (localStorage.getItem('lang') === 'tr') {
            return s.main_text_tur
        } else {
            return s.main_text_ru
        }
    }

    const getClassNameSubText = () => {
        if (localStorage.getItem('lang') === 'en') {
            return s.main_text2
        } else if (localStorage.getItem('lang') === 'tr') {
            return s.main_text2_tur
        } else {
            return s.main_text2_ru
        }
    }

    return (
        <div>
            <MyPopupSuccess visible={popupVisible} setVisible={setPopupVisible}/>
            <MyPopupError visible={popupErrorVisible} setVisible={setPopupErrorVisible}/>
            <div className={s.main}>
                <img src={mainBg} alt="" className={s.main_bg}/>

                <div className={s.blur}>
                    <div className={classNames(getClassNameText(), 'ttr-title')}>
                        {t('contact.contact')}
                        <div className={classNames(getClassNameSubText(), 'ttr-title')}>
                            {t('contact.us')}
                        </div>
                    </div>
                </div>

            </div>

            <div className={s.main_mobile}>
                <div className={classNames(s.main_text, 'ttr-title')}>
                    {t('contact.contact')} {t('contact.us')}
                </div>
            </div>
            <div className={s.contact_us_content}>
                <div className={s.left}>
                    <div className={classNames(s.title, 'ttr-title')}>
                        {t('contact.reach_us')}
                    </div>
                    <div className={s.welcome_block}>
                        <div className={s.welcome}>
                            {t('contact.welcome_to')}
                        </div>
                        <div className={s.contacts}>
                            <div className={s.img}><img src={map} alt=""/></div>
                            <div>5C4V+V9M, Tifariti, SADR</div>
                        </div>
                        <div className={s.contacts}>
                            <div className={s.img}><img src={mail} alt=""/></div>
                            <div>info@turkishmailservice.com</div>
                        </div>
                        <div className={s.contacts}>
                            <div className={s.img}><img src={phone} alt=""/></div>
                            <div>+44 2045774192</div>
                        </div>
                        <div className={s.cyprus_office}>
                            <div className={s.cyprus_title}>
                                {t('contact.cyprus_title')}
                            </div>
                            <div className={s.contacts}>
                                <div className={s.img}><img src={map} alt=""/></div>
                                <div>
                                    {t('contact.cyprus_address_1')} <br/>
                                    {t('contact.cyprus_address_2')} <br/>
                                    {t('contact.cyprus_address_3')} <br/>
                                </div>
                            </div>
                            <div className={s.contacts} style={{marginBottom: '20px'}}>
                                <div className={s.img}><img src={phone} alt=""/></div>
                                <div>+35722030061</div>
                            </div>
                        </div>
                        <div className={s.social_block}>
                            <a href="https://www.linkedin.com/company/turkish-international-investment-bank"
                               target="_blank">
                                <div className={s.social_link}>
                                    <img className={s.img} src={linkedin} alt=""/>
                                    <div className={s.social_link_text}>LinkedIn</div>
                                </div>
                            </a>

                            <a href="https://www.facebook.com/turkish.international.bank" target="_blank">
                                <div className={s.social_link}>
                                    <img className={s.img} src={facebook} alt=""/>
                                    <div className={s.social_link_text}>Facebook</div>
                                </div>
                            </a>

                            <a href="https://wa.me/+447700306857" target="_blank">
                                <div className={s.social_link}>
                                    <img className={s.img} src={whatsapp} alt=""/>
                                    <div className={s.social_link_text}>WhatsApp</div>
                                </div>
                            </a>

                            <a href="https://t.me/+447700306857" target="_blank">
                                <div className={s.social_link}>
                                    <img className={s.img} src={telegram} alt=""/>
                                    <div className={s.social_link_text}>Telegram</div>
                                </div>
                            </a>

                            <a href="https://signal.group/#CjQKIClfotfLAbvrDwEvKW9gwt-LeX5LN92W6A1JYttrZ9kVEhCPZ5xtOyGZu5NXGGKzC95q"
                               target="_blank">
                                <div className={s.social_link}>
                                    <img className={s.img} src={signal} alt=""/>
                                    <div className={s.social_link_text}>Signal</div>
                                </div>
                            </a>

                            {/*<a href="#" target="_blank">*/}
                            {/*    <div className={s.social_link}>*/}
                            {/*        <img className={s.img} src={insta} alt=""/>*/}
                            {/*        <div className={s.social_link_text}>Instagram</div>*/}
                            {/*    </div>*/}
                            {/*</a>*/}
                        </div>
                    </div>

                </div>
                <div className={s.right}>
                    <div className={s.inputs_block}>
                        <div className={s.input_name_block}>
                            <div className={s.input_name}>
                                {t('contact.name')}
                            </div>
                            <input className={s.my_input}
                                   type="text"
                                   value={data.firstName}
                                   onChange={(e) => {
                                       setData({...data, firstName: e.currentTarget.value})
                                   }}
                            />
                        </div>
                        <div className={s.input_name_block}>
                            <div className={s.input_name}>
                                {t('contact.phone')}
                            </div>
                            <input className={s.my_input}
                                   type="text"
                                   value={data.phone}
                                   onChange={(e) => {
                                       setData({...data, phone: e.currentTarget.value})
                                   }}
                            />
                        </div>
                        <div className={s.mail}>
                            <div className={s.input_name}>
                                {t('popup.email')}
                            </div>
                            <input className={s.my_input}
                                   type="text"
                                   value={data.email}
                                   onChange={(e) => {
                                       setData({...data, email: e.currentTarget.value})
                                   }}
                            />
                        </div>
                    </div>
                    <div className={s.textarea_block}>
                        <div className={s.input_name}>{t('contact.message')}</div>
                        <textarea className={s.my_textarea}
                                  value={data.message}
                                  onChange={(e) => {
                                      setData({...data, message: e.currentTarget.value})
                                  }}
                        />
                    </div>
                    <button className={s.my_btn} onClick={sendMessage}>
                        {t('main.send')}
                    </button>

                </div>
            </div>
        </div>
    );
};


export default withTranslation()(ContactUs)
