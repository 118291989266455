import React from "react";
import Popup from "reactjs-popup";
import { Alert } from "../../utils/alert";
import Confirm from "./layouts/Confirm";
import Message from "./layouts/Message";

function PopupAlert() {
  return (
    <Popup
      ref={(ref) => Alert.setPopup(ref)}
      position="center"
      modal
      autoFocus="false"
      nested
      closeOnDocumentClick={false}
      closeOnEscape={false}
      className="modal modal-sm modal-fade"
      children={(props) => {
        const data = Alert.getInfo();
        switch (data?.type) {
          case "info" || "success" || "warning" || "error":
            return <Message data={data} />;
          case "confirm":
            return <Confirm data={data} />;
          default:
            return <Message data={data} />;
        }
      }}
    />
  );
}

export default PopupAlert;
